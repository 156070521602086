import React, { useEffect, useState } from "react";
import { List, notification, Drawer, Input, Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import UpdateGoogleSheet from "./UpdateGoogleSheet";
import { getGoogleSheets } from "../../api/googleSheetsService";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const GoogleSheetsList = () => {
  const [sheets, setSheets] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [range, setRange] = useState("");
  const [values, setValues] = useState("");
  const location = useLocation();
  const { userData } = useAuth();
  const { getTranslation } = useLocalization;

  useEffect(() => {
    setIsAuthenticated(true);
    fetchSheets();
  }, [location.search]);

  const fetchSheets = async () => {
    try {
      const response = await getGoogleSheets(userData?.orgId);
      setSheets(response.data);
      setLoading(false);
      setIsAuthenticated(true);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Google Sheets:", error);
      notification.error({
        message: getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsList.error_message"
        ),
        description: getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsList.error_description"
        ),
      });
    }
  };

  const showDrawer = (sheet) => {
    setSelectedSheet(sheet);
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setSelectedSheet(null);
  };

  if (loading)
    return (
      <div>
        <Spin />
      </div>
    );

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">
        {getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsList.title"
        )}
      </h2>
      {isAuthenticated ? (
        <>
          <List
            bordered
            dataSource={sheets}
            renderItem={(sheet) => (
              <List.Item
                onClick={() => showDrawer(sheet)}
                style={{ cursor: "pointer" }}
              >
                {sheet.name} - {sheet.id}
              </List.Item>
            )}
          />
          <Drawer
            title={`Edit ${selectedSheet?.name}`}
            placement="right"
            onClose={closeDrawer}
            visible={visible}
          >
            <UpdateGoogleSheet sheetId={selectedSheet?.id} />
          </Drawer>
        </>
      ) : (
        <p>
          {getTranslation(
            "settings.Integrations.google_sheet.GoogleSheetsList.loading_message"
          )}
        </p>
      )}
    </div>
  );
};

export default GoogleSheetsList;
