import { createContext, useContext, useState, useEffect } from "react";

import en from "../locals/en.json";
import fr from "../locals/fr.json";

export const LocalizationContext = createContext();

const languageData = {
  en,
  fr,
};

export const LocalizationProvider = ({ children }) => {
  // Initialize state from localStorage or fallback to default ('en')
  const [language, setLanguage] = useState(
    localStorage.getItem("appLanguage") || "en"
  );

  useEffect(() => {
    // Effect for updating localStorage when language changes
    localStorage.setItem("appLanguage", language);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  const getTranslation = (key, count) => {
    const keys = key.split(".");
    let translation = languageData[language] || languageData.en; // Fallback to English data

    const findTranslation = (translationObj) => {
      return keys.reduce((currentObj, key) => {
        // Check if the current object has the key and the value is not undefined
        return currentObj && key in currentObj ? currentObj[key] : undefined;
      }, translationObj);
    };

    translation =
      findTranslation(translation) !== undefined
        ? findTranslation(translation)
        : findTranslation(languageData.en);

    // Return the key itself if translation is undefined or null
    if (translation === undefined || translation === null) {
      return key;
    }

    // If translation is a string, return it (even if it's an empty string)
    if (typeof translation === "string") {
      return translation;
    }

    // Handle pluralization if translation is an object with _plural key
    if (typeof translation === "object" && translation._plural) {
      const form = count === 1 ? "one" : "other";
      return translation[form]
        ? translation[form].replace("{count}", count)
        : key;
    }

    return key; // Fallback for object but not plural form
  };

  return (
    <LocalizationContext.Provider
      value={{ language, changeLanguage, getTranslation }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (context === undefined) {
    throw new Error(
      "useLocalization must be used within a LocalizationProvider"
    );
  }
  return context;
};
