import { loadStripe } from "@stripe/stripe-js";
import { stripeCreateCheckoutSession } from "../api/payment";
import apiClient from "../api/index";
import { updateOrganization } from "../api/firebase/organizationApi";
import { message } from "antd";
import { useAuth } from "../AuthContext";


export const checkoutSession = async (packageData,organizationId,planId,client_reference_id) => {

  const stripe = await loadStripe(
    "pk_test_51NjNGfLEnnoyDVSaCebemaxrv0JR0sijzU1GScpIK6CeonkZkbFJgsI9GqBht5oSNczf6OTEDZTefJHcgAO6VOOm00TdFSlrh1"
  );  


  if(!packageData || !organizationId || !planId || !client_reference_id){
    message.error("Please refresh and if not resolve then connect to admin")
    return
  }

  const session = await stripeCreateCheckoutSession({ ...packageData, organizationId, planId, client_reference_id});

  const result = stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    message.error(result.error)
    return
  }
};

export const fetchAllCalls = async (page = 1, size = 30) => {
  let allCalls = [];
  let totalCalls = 0;

  while (true) {
    const response = await apiClient.get("/calls", {page, size});
    const calls = response.data.data;
    totalCalls = response.data.total;

    allCalls = [...allCalls, ...calls];

    if (calls.length < size) break;
    page += 1;
  }

  return allCalls;
};

export const calculateTotalDurations = (calls) => {
  const durations = calls.reduce((acc, call) => {
    const fromNumber = call.fromNumber;
    const duration =
      call.duration !== "Not Available"
        ? parseInt(call.duration.split(" ")[0])
        : 0;

    if (!acc[fromNumber]) {
      acc[fromNumber] = 0;
    }

    acc[fromNumber] += duration;

    return acc;
  }, {});

  return durations;
};

export const removeEmptyValues = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export function getPropertiesKeys(json) {
  if (json && json.function && json.function.parameters && json.function.parameters.properties) {
      return Object.keys(json.function.parameters.properties);
  }
  return [];
}