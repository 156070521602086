// src/pages/others/UnauthorizedPage.js
import React from "react";
import { Button, Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const { Title, Paragraph } = Typography;

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { getTranslation } = useLocalization();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <Card className="w-full max-w-3xl p-8">
        <Title level={2}>{getTranslation("unauthorized.title")}</Title>
        <Paragraph>{getTranslation("unauthorized.message")}</Paragraph>
        <Button type="primary" onClick={handleLogout}>
          {getTranslation("authentication.logout.logoutButton")}
        </Button>
      </Card>
    </div>
  );
};

export default UnauthorizedPage;
