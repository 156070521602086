import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Switch,
  Input,
  Button,
  Space,
  Tag,
  Modal,
  Checkbox,
  message,
  Typography,
  Drawer,
  Spin,
  Divider,
  Breadcrumb,
  Descriptions,
} from "antd";
import { SettingOutlined, ExportOutlined } from "@ant-design/icons";
import { getCallById, fetchCallsByOrgId } from "../../api/callApi";
import {
  fetchAgentById,
  fetchAgentByOrgId,
  fetchAgents,
} from "../../api/agentApi";
import moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { useAuth } from "../../AuthContext";
import { fetchPhoneNumbers } from "../../api/phoneNumbers";
import GenericTable from "../../components/GenericTable";
import PlayAud from "../../assets/play_audio.png";
import messageTextSquare from "../../assets/message-text-square-02.png";
import database from "../../assets/database-02.png";
import tick from "../../assets/rightTick.png";
import error from "../../assets/error-warning-fill.png";
import alertInfo from "../../assets/alert-fill.png";
import pause from "../../assets/pause.png";
import eye from "../../assets/eye.png";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

const icons = {
  play: PlayAud,
  message: messageTextSquare,
  database: database,
  tick: tick,
  error: error,
  alert: alertInfo,
  pause,
};

const { Search } = Input;

const CallPage = ({ type, title }) => {
  const { getTranslation } = useLocalization(); // Use the localization hook
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideSensitive, setHideSensitive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [agentDetails, setAgentDetails] = useState(null);
  const [isAgentModalVisible, setIsAgentModalVisible] = useState(false);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalCalls, setTotalCalls] = useState(0);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const audioRef = useRef(null);
  const { userData, serviceConfig } = useAuth();
  const service = serviceConfig?.service;
  const [collectedData, setCollectedData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const defaultColumns = [
    "agent",
    "recording",
    "startTime",
    "duration",
    "fromNumber",
    "toNumber",
    "status",
    "actions",
  ];

  useEffect(() => {
    const storedColumns = localStorage.getItem("selectedColumns");
    if (storedColumns) {
      setSelectedColumns(JSON.parse(storedColumns));
    } else {
      setSelectedColumns(defaultColumns);
    }

    // getAllAgents();
    getAllNumbers();
    fetchCallsData(currentPage, pageSize);
  }, []);

  const getAllAgents = async () => {
    const res = await fetchAgentByOrgId(userData.orgId);
    setAgents(res);
  };

  const getAllNumbers = async () => {
    const numbersData = await fetchPhoneNumbers(userData.orgId);
    setNumbers(numbersData);
  };

  const fetchCallsData = async (page, size) => {
    setLoading(true);
    try {
      const orgId = userData?.orgId;
      const callsData = await fetchCallsByOrgId(orgId, type);

      setCalls(callsData);

      setTotalCalls(callsData?.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(
        getTranslation("call.CallPage.fetchCallDetailsFailure"),
        error
      );
    }
  };

  const handleViewCollectedData = (record) => {
    setCollectedData(record?.collectedData);
    showDrawer();
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const [loadingMsg, setLoadingMsg] = useState(false);

  const handleViewCall = async (id) => {
    setLoadingMsg(true);
    try {
      const callDetails = await getCallById(id);
      setSelectedCall(callDetails);
      setIsDrawerVisible(true);
      setLoadingMsg(false);
    } catch (error) {
      setLoadingMsg(false);
      message.error(getTranslation("call.CallPage.fetchCallDetailsFailure"));
      console.error(
        getTranslation("call.CallPage.fetchCallDetailsFailure"),
        error
      );
    }
  };

  const formatTranscript = (transcript) => {
    return transcript.split("\n").map((line, index) => (
      <p key={index} className="mt-2 border-b-1">
        {line}
      </p>
    ));
  };

  const handleExport = () => {
    const csvData = calls.map((call) => {
      const agent = agents?.find(
        (item) => item.agentResponse.id === call?.assistantId
      );

      const numberDetails = numbers?.find(
        (item) => item.id == call?.phoneNumberId
      );

      return {
        agent: agent?.agentName,
        recording: call.recordingUrl ? "Available" : "Not available",
        startTime: call.startedAt,
        endTime: call.endedAt,
        duration: call.duration,
        from: hideSensitive ? "Hidden" : numberDetails?.number,
        to: hideSensitive ? "Hidden" : call?.customer?.number,
        status: call.status,
      };
    });

    const csvString = [
      [
        getTranslation("call.CallPage.agent"),
        getTranslation("call.CallPage.recording"),
        getTranslation("call.CallPage.startTime"),
        getTranslation("call.CallPage.endTime"),
        getTranslation("call.CallPage.Duration"),
        getTranslation("call.CallPage.fromNumber"),
        getTranslation("call.CallPage.toNumber"),
        getTranslation("call.CallPage.status"),
      ],
      ...csvData.map((item) => [
        item.agent,
        item.recording,
        item.startTime,
        item.endTime,
        item.duration,
        item.from,
        item.to,
        item.status,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "calls.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleHideSensitiveChange = (checked) => {
    setHideSensitive(checked);
  };

  const handleSearch = (value) => {
    const filteredData = calls.filter(
      (call) =>
        call.agent.name.toLowerCase().includes(value.toLowerCase()) ||
        call.fromNumber.toLowerCase().includes(value.toLowerCase()) ||
        call.toNumber.toLowerCase().includes(value.toLowerCase())
    );
    setCalls(filteredData);
  };

  const handleEditColumns = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const handleAgentClick = async (id) => {
    setLoading(true);
    try {
      const agent = await fetchAgentById(id);
      setAgentDetails(agent);
      setIsAgentModalVisible(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(getTranslation("call.CallPage.fetchAgentDetailsFailure"));
      console.error(
        getTranslation("call.CallPage.fetchAgentDetailsFailure"),
        error
      );
    }
  };

  const playRecording = (url) => {
    if (audioRef.current) {
      if (currentPlaying === url) {
        audioRef.current.pause();
        setCurrentPlaying(null);
      } else {
        if (currentPlaying) {
          audioRef.current.pause();
        }
        audioRef.current.src = url;
        audioRef.current.play();
        setCurrentPlaying(url);
      }
    }
  };

  const allColumns = [
    {
      title: getTranslation("call.CallPage.agent"),
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => {
        return (
          <Typography.Text>{record?.agentData?.name || "-"}</Typography.Text>
        );
      },
    },
    {
      title: getTranslation("call.CallPage.recording"),
      dataIndex: "recording",
      key: "recording",
      render: (text, record) => (
        <div
          onClick={() =>
            record?.status?.toLowerCase()?.includes("ended")
              ? playRecording(record.recordingUrl)
              : message.error(
                  getTranslation("call.CallPage.recordingNotAvailable")
                )
          }
          className="w-14  cursor-pointer rounded-lg shadow-sm border border-gray-200 flex items-center justify-center"
        >
          {currentPlaying === record.recordingUrl ? (
            <img src={icons.pause} className="w-[12px] h-[12px] mr-1" />
          ) : (
            <img src={icons.play} className="w-[12px] h-[12px] mr-1" />
          )}

          {record?.duration ? `${record?.duration?.toFixed(2)}` : "-"}
        </div>
        // <div className="cursor-pointer">
        //   {record?.recordingUrl ? (
        //     <div onClick={() => playRecording(record.recordingUrl)}>
        //       {currentPlaying === record.recordingUrl ? (
        //         <PauseCircleOutlined />
        //       ) : (
        //         <PlayCircleOutlined />
        //       )}
        //     </div>
        //   ) : (
        //     <CloseCircleOutlined style={{ color: "red" }} />
        //   )}
        // </div>
      ),
    },
    {
      title: getTranslation("call.CallPage.startTime"),
      dataIndex: "startTime",
      key: "startTime",
      render: (text, record) => (
        <Typography.Text className="text-xs">
          {record?.status === "ended"
            ? moment(record?.startTime || record.startedAt).format(
                "MMM Do YYYY, h:mm"
              )
            : getTranslation("call.CallPage.notAvailable")}
        </Typography.Text>
      ),
    },
    {
      title: getTranslation("call.CallPage.endTime"),
      dataIndex: "endTime",
      key: "endTime",
      render: (text, record) => (
        <Typography.Text className="text-xs">
          {record?.status === "ended"
            ? moment(record?.endTime || record.endedAt).format(
                "MMM Do YYYY, h:mm"
              )
            : getTranslation("call.CallPage.notAvailable")}
        </Typography.Text>
      ),
    },
    {
      title: getTranslation("call.CallPage.fromNumber"),
      dataIndex: "fromNumber",
      key: "fromNumber",
      render: (text, record) => {
        const numberDetails = numbers?.find(
          (item) => item.id == record?.phoneNumberId
        );

        return (
          <>
            {hideSensitive
              ? "........"
              : numberDetails?.number || record?.fromNumber || "-"}
          </>
        );
      },
    },
    {
      title: getTranslation("call.CallPage.toNumber"),
      dataIndex: "toNumber",
      key: "toNumber",
      render: (text, record) =>
        hideSensitive ? "........" : text || record?.customer?.number,
    },
    {
      title: getTranslation("call.CallPage.status"),
      dataIndex: "status",
      key: "status",
      // render: (status) => {
      //   let color = "default";
      //   if (status === "ended") color = "green";
      //   else if (status === "Not Started") color = "red";
      //   return <Tag color={color}>{status?.toUpperCase()}</Tag>;
      // },

      render: (value) => (
        <div className="flex items-center w-20 justify-center px-1 py-1 rounded-md border">
          <div>
            {value?.toLowerCase()?.includes("ended") ? (
              <img src={icons.tick} className="w-3 h-3" />
            ) : value?.toLowerCase()?.includes("failed") ? (
              <img src={icons.error} className="w-4 h-4" />
            ) : value?.toLowerCase()?.includes("declined") ? (
              <img src={icons.alert} className="w-4 h-4" />
            ) : (
              <img src={icons.alert} className="w-4 h-4" />
            )}
          </div>
          <span className="ml-1">{value}</span>
        </div>
      ),
    },
    {
      title: getTranslation("call.CallPage.actions"),
      key: "actions",
      render: (text, record) => (
        <>
          {loading ? (
            <Spin size="small" />
          ) : (
            <div className="flex items-center justify-center cursor-pointer">
              <div>
                {record?.collectedData ? (
                  <img
                    src={icons.database}
                    className="w-4 h-4 mr-3"
                    onClick={() => handleViewCollectedData(record)}
                  />
                ) : (
                  <div className="w-4 h-4 mr-3" />
                )}
              </div>

              {loadingMsg ? (
                <Spin />
              ) : (
                <img
                  src={icons.message}
                  className="w-4 h-4"
                  onClick={() =>
                    handleViewCall(
                      record.service === "vapi" ? record.call_id : record.id
                    )
                  }
                />
              )}
            </div>
          )}
        </>

        // <Button
        //   icon={<EyeOutlined />}
        //   onClick={() =>
        //     handleViewCall(
        //       record.service === "vapi" ? record.call_id : record.id
        //     )
        //   }
        // >
        //   View Transcript
        // </Button>
      ),
    },
  ];

  const columns = allColumns.filter((col) => selectedColumns.includes(col.key));

  return (
    <div className="min-h-screen bg-white">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: title,
            },
            {
              title: "History",
            },
          ]}
        />
      </div>
      <Divider />

      <Drawer
        title={getTranslation("call.CallPage.collected_data_title")}
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={400}
      >
        {collectedData ? (
          <Descriptions bordered column={1}>
            {Object.keys(collectedData).map((key) => (
              <Descriptions.Item label={key} key={key}>
                {typeof collectedData[key] === "boolean"
                  ? collectedData[key]
                    ? "Yes"
                    : "No"
                  : collectedData[key]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        ) : (
          <p>{getTranslation("call.CallPage.no_data_message")}</p>
        )}
      </Drawer>

      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold"></h1>
          <Space>
            <Switch
              checkedChildren={getTranslation(
                "call.CallPage.hideSensitiveData"
              )}
              unCheckedChildren={getTranslation(
                "call.CallPage.showSensitiveData"
              )}
              onChange={handleHideSensitiveChange}
              className="w-20"
            />
            {/* <Button size="small"  className="text-xs rounded-lg">
              <img src={eye} className="w-2 h-2 object-contain" />
            </Button> */}
            {/* <Search
              placeholder="Search"
              onSearch={handleSearch}
              style={{ width: 200 }}
            /> */}
            <Button
              size="small"
              className="text-xs rounded-lg"
              icon={<SettingOutlined />}
              onClick={handleEditColumns}
            >
              {getTranslation("call.CallPage.editColumns")}
            </Button>
            <Button
              size="small"
              className="text-xs rounded-lg"
              onClick={handleExport}
              icon={<ExportOutlined />}
            >
              {getTranslation("call.CallPage.export")}
            </Button>
          </Space>
        </div>
        <GenericTable data={calls} columns={columns} loading={loading} />
        {/* <Table
          columns={columns}
          dataSource={calls}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCalls,
            pageSizeOptions: ["10", "20", "30", "50", "100"],
            showSizeChanger: true,
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
              fetchCallsData(page, size);
            },
          }}
          className="bg-white p-4 rounded-lg border"
        /> */}
        <Modal
          title={getTranslation("call.CallPage.editColumnsModalTitle")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Checkbox.Group
            style={{ width: "100%" }}
            value={selectedColumns}
            onChange={handleCheckboxChange}
          >
            {allColumns.map((col) => (
              <Checkbox key={col.key} value={col.key}>
                {col.title}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Modal>
        <Modal
          title={getTranslation("call.CallPage.agentDetailsModalTitle")}
          visible={isAgentModalVisible}
          onCancel={() => setIsAgentModalVisible(false)}
          footer={null}
        >
          {agentDetails && (
            <div>
              <p>
                <strong>{getTranslation("call.CallPage.agentID")}:</strong>{" "}
                {agentDetails.id}
              </p>
              <p>
                <strong>{getTranslation("call.CallPage.agentName")}:</strong>{" "}
                {agentDetails.name}
              </p>
              <p>
                <strong>
                  {getTranslation("call.CallPage.agentLanguage")}:
                </strong>{" "}
                {agentDetails.language}
              </p>
              <p>
                <strong>{getTranslation("call.CallPage.agentPrompt")}:</strong>{" "}
                {agentDetails.prompt.content}
              </p>
              <p>
                <strong>{getTranslation("call.CallPage.agentActions")}:</strong>{" "}
                {agentDetails.actions.map((action) => (
                  <div key={action.id}>
                    <p>
                      <strong>
                        {getTranslation("call.CallPage.agentActionType")}:
                      </strong>{" "}
                      {action.type}
                    </p>
                    <p>
                      <strong>
                        {getTranslation("call.CallPage.agentActionName")}:
                      </strong>{" "}
                      {action.config.name}
                    </p>
                    <p>
                      <strong>
                        {getTranslation("call.CallPage.agentActionDescription")}
                        :
                      </strong>{" "}
                      {action.config.description}
                    </p>
                  </div>
                ))}
              </p>
              <p>
                <strong>{getTranslation("call.CallPage.agentVoice")}:</strong>{" "}
                {agentDetails.voice.type}
              </p>
            </div>
          )}
        </Modal>

        <Drawer
          title={getTranslation("call.CallPage.transcriptDrawerTitle")}
          visible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          width={500}
        >
          {selectedCall ? (
            <div>
              <div>{formatTranscript(selectedCall.transcript || "N/A")}</div>
            </div>
          ) : (
            <p>{getTranslation("call.CallPage.loading")}</p>
          )}
        </Drawer>
        <audio ref={audioRef} controls style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default CallPage;
