// src/pages/AgentCatalog.js
import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Typography,
  Button,
  Divider,
  Breadcrumb,
  Drawer,
} from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { fetchAgentByOrgId } from "../../api/agentApi";
import plus_white from "../../assets/plus_white.png";
import edit_icon from "../../assets/edit_icon.png";
import GenericTable from "../../components/GenericTable";
import countryJsonWithEmoji from "../../data/countryJsonWithEmoji.json"; // Adjust the path as necessary
import EditAgentDrawer from "./EditAgentDrawer";
import { useLocalization } from "../../context/LocalizationContext";
import Label from "../../components/Label";

const { Title } = Typography;

const AgentCatalog = () => {
  const { getTranslation } = useLocalization();
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [openCreateAgent, setOpenCreateAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const { userData } = useAuth();
  const navigate = useNavigate();

  const fetchAgents = async () => {
    try {
      const agentsData = await fetchAgentByOrgId(userData?.orgId);
      setAgents(agentsData);
      console.log(JSON.stringify(agentsData[1]));
      
      setLoading(false);
      setOpenCreateAgent(false);
    } catch (error) {
      console.error("Error fetching agents:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const agentModelHandler = () => {
    setSelectedAgent(null);
    setOpenCreateAgent(!openCreateAgent);
  };

  
  const columns = [
    {
      title: getTranslation("agents.AgentCatalog.agentColumn"),
      dataIndex: "agentName",
      key: "agentName",
    },
    {
      title: getTranslation("agents.AgentCatalog.languageColumn"),
      dataIndex: "language",
      render: (tasks, record) => {
        const cntry = countryJsonWithEmoji.find(
          (cnt) =>
            record?.agentResponse?.transcriber?.language?.toLowerCase() ===
            cnt?.code?.toLowerCase()
        );
        return (
          <Button size="small" className="rounded-xl bg-gray-200 text-xs">
            {cntry
              ? `${cntry?.emoji} ${cntry?.name}`
              : record?.agentResponse?.transcriber?.language}
          </Button>
        );
      },
    },
    {
      title: getTranslation("agents.AgentCatalog.initialMessageColumn"),
      dataIndex: "inititalMessgae",
      key: "inititalMessgae",
      render: (tasks, record) => {
        return (
          <>
            {record.agentResponse?.initialMessage ||
              record.agentResponse?.firstMessage}
          </>
        );
      },
    },
    {
      title: getTranslation("agents.AgentCatalog.attachPhoneNumberColumn"),
      dataIndex: "attachPhoneNumber",
      key: "attachPhoneNumber",
      render: (tasks, record) => {
        return (
          <>
            {!record.attachPhoneNumber?.number ? (
              <>-</>
            ) : (
              <>{record.attachPhoneNumber?.number}</>
            )}
          </>
        );
      },
    },
    {
      title: getTranslation("agents.AgentCatalog.actionColumn"),
      key: "action",
      render: (text, record) => (
        <>
          <img
            className="cursor-pointer w-3 h-3"
            onClick={() =>
              // navigate(
              //   `/agents/update/${record.agentResponse.id}?docId=${record.id}`
              // )

              {
                setSelectedAgent({
                  docId: record.id,
                  ...record,
                });

                setOpenCreateAgent(true);
              }
            }
            src={edit_icon}
          />
        </>
      ),
    },
  ];

  return (
    <div className="bg-white min-h-screen">
      <div className="flex items-center justify-between px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation("agents.AgentCatalog.breadcrumbTitle"),
            },
          ]}
        />

        <Button
          className="bg-black text-white flex items-center"
          // onClick={() => navigate("/agents/update")}
          onClick={() => agentModelHandler()}
        >
          <img src={plus_white} className="w-4 h-4 object-contain mr-1" />
          {getTranslation("agents.AgentCatalog.createAgentButton")}
        </Button>
      </div>

      <Divider />
      <Drawer
        title={
          <Label>
            {selectedAgent ? (
              <>
                Edit{" "}
                <span className="text-blue-700 font-medium">
                  {selectedAgent?.agentName}
                </span>{" "}
              </>
            ) : (
              "Create new agent"
            )}
          </Label>
        }
        open={openCreateAgent}
        closable
        onClose={() => agentModelHandler()}
      >
        <EditAgentDrawer
          selectedAgentData={selectedAgent}
          fetchAgents={fetchAgents}
        />
      </Drawer>

      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Spin size="small" />
        </div>
      ) : (
        <GenericTable data={agents} columns={columns} loading={loading} />
        // <Table
        //   columns={columns}
        //   dataSource={agents}
        //   rowKey="id"
        //   className="bg-white p-4 rounded-lg"
        //   pagination={{ pageSize: 5 }}
        // />
      )}
    </div>
  );
};

export default AgentCatalog;
