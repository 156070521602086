import React, { useState } from "react";
import { Form, Input, Button, Select, Tabs, message } from "antd";
import GlobalModal from "../../components/GlobalModal";
import { phoneNumbersCreate } from "../../api/phoneNumbers";
import countries from "../../data/countries.json"; // Adjust the path as necessary
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

const { TabPane } = Tabs;
const { Option } = Select;

const ImportPhoneNumberModal = ({ fetchNumbers, type, text }) => {
  const { getTranslation } = useLocalization(); // Use the localization hook
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("twilio");
  const [selectedCountry, setSelectedCountry] = useState(
    countries[0].dial_code
  ); // Default to the first country
  const { userData } = useAuth();

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleImport = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const requestData = {
        provider: activeTab,
        number: "+" + values.phoneNumber,
        name: values.label,
      };

      if (activeTab === "twilio") {
        requestData.twilioAccountSid = values.accountSID;
        requestData.twilioAuthToken = values.authToken;
      } else if (activeTab === "vonage") {
        requestData.apiKey = values.apiKey;
        requestData.apiSecret = values.apiSecret;
      }

      await phoneNumbersCreate(requestData, userData.orgId);
      if (fetchNumbers) {
        fetchNumbers();
      }
      setLoading(false);
      setVisible(false);
      message.success(
        getTranslation("phoneNumber.ImportPhoneNumberModal.importSuccess")
      );
    } catch (error) {
      
      console.error(
        "Failed to import phone number:",
        error
      );
      setLoading(false);
      message.error(
        error ||
          getTranslation("phoneNumber.ImportPhoneNumberModal.importFailure")
      );
    }
  };

  return (
    <div>
      <Button type={type || "primary"} onClick={handleOpenModal}>
        {text ||
          getTranslation("phoneNumber.ImportPhoneNumberModal.importButton")}
      </Button>
      <GlobalModal
        title={
          text || getTranslation("phoneNumber.ImportPhoneNumberModal.title")
        }
        visible={visible}
        onOk={handleImport}
        onCancel={handleCloseModal}
        loading={loading}
        requiredPermission="canImportPhoneNumber"
      >
        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane
            tab={getTranslation(
              "phoneNumber.ImportPhoneNumberModal.tabs.twilio"
            )}
            key="twilio"
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name="country"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.countryLabel"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.countryError"
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={getTranslation(
                    "phoneNumber.ImportPhoneNumberModal.countryPlaceholder"
                  )}
                  optionFilterProp="children"
                  onChange={handleCountryChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries.map((country) => (
                    <Option key={country.code} value={country.dial_code}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.phoneNumberLabelTwilio"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.phoneNumberError"
                    ),
                  },
                ]}
              >
                <Input addonBefore={selectedCountry} />
              </Form.Item>
              <Form.Item
                name="accountSID"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.accountSIDLabel"
                )}
                rules={[
                  {
                    required: activeTab === "twilio",
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.accountSIDError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="authToken"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.authTokenLabel"
                )}
                rules={[
                  {
                    required: activeTab === "twilio",
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.authTokenError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="label"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.labelLabel"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.labelError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={getTranslation(
              "phoneNumber.ImportPhoneNumberModal.tabs.vonage"
            )}
            key="vonage"
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name="country"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.countryLabel"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.countryError"
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={getTranslation(
                    "phoneNumber.ImportPhoneNumberModal.countryPlaceholder"
                  )}
                  optionFilterProp="children"
                  onChange={handleCountryChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries.map((country) => (
                    <Option key={country.code} value={country.dial_code}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.phoneNumberLabelVonage"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.phoneNumberError"
                    ),
                  },
                ]}
              >
                <Input addonBefore={selectedCountry} />
              </Form.Item>
              <Form.Item
                name="apiKey"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.apiKeyLabel"
                )}
                rules={[
                  {
                    required: activeTab === "vonage",
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.apiKeyError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="apiSecret"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.apiSecretLabel"
                )}
                rules={[
                  {
                    required: activeTab === "vonage",
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.apiSecretError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="label"
                label={getTranslation(
                  "phoneNumber.ImportPhoneNumberModal.labelLabel"
                )}
                rules={[
                  {
                    required: true,
                    message: getTranslation(
                      "phoneNumber.ImportPhoneNumberModal.labelError"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </GlobalModal>
    </div>
  );
};

export default ImportPhoneNumberModal;
