// src/pages/RoleManagement.js
import React, { useState, useEffect } from "react";
import { Button, Table, Form, Input, message } from "antd";
import {
  getAllRoles,
  createRole,
  updateRole,
  deleteRole,
} from "../../api/firebase/roleApi";
import withAuthorization from "../../hoc/withAuthorization";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import GlobalModal from "../../components/GlobalModal";
import { useLocalization } from "../../context/LocalizationContext";

const RoleManagement = () => {
  const { getTranslation } = useLocalization(); // Using localization
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [deleteRoleId, setDeleteRoleId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const roles = await getAllRoles();
      setRoles(roles);
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation("roles.RoleManagement.messages.fetchRolesError")
      );
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setEditRole(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (role) => {
    setEditRole(role);
    setIsModalVisible(true);
    form.setFieldsValue(role);
  };

  const handleDelete = (roleId) => {
    setDeleteRoleId(roleId);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteRole(deleteRoleId);
      message.success(
        getTranslation("roles.RoleManagement.messages.deleteRoleSuccess")
      );
      fetchRoles();
    } catch (error) {
      message.error(
        getTranslation("roles.RoleManagement.messages.deleteRoleError")
      );
      setLoading(false);
    }
    setIsDeleteModalVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = form.getFieldsValue();
      if (editRole) {
        await updateRole(editRole.id, values);
        message.success(
          getTranslation("roles.RoleManagement.messages.saveRoleSuccess")
        );
      } else {
        await createRole({ id: values?.name?.toLowerCase(), ...values });
        message.success(
          getTranslation("roles.RoleManagement.messages.saveRoleSuccess")
        );
      }
      setIsModalVisible(false);
      fetchRoles();
    } catch (error) {
      message.error(
        getTranslation("roles.RoleManagement.messages.saveRoleError")
      );
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const columns = [
    {
      title: getTranslation("roles.RoleManagement.nameColumn"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: getTranslation("roles.RoleManagement.descriptionColumn"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: getTranslation("roles.RoleManagement.actionsColumn"),
      key: "actions",
      render: (text, record) => (
        <span>
          <Button className="mr-2" onClick={() => handleEdit(record)}>
            {getTranslation("roles.RoleManagement.editRoleButton")}
          </Button>
          <Button onClick={() => handleDelete(record.id)} danger>
            {getTranslation("roles.RoleManagement.deleteRoleButton")}
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div className="p-10">
      <Button type="primary" onClick={handleCreate} className="mb-4">
        {getTranslation("roles.RoleManagement.createRoleButton")}
      </Button>
      <Table
        columns={columns}
        dataSource={roles}
        loading={loading}
        rowKey="id"
      />
      <GlobalModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        loading={loading}
        requiredPermission={editRole ? "canUseRolesEdit" : "canUseRolesCreate"}
        title={
          editRole
            ? getTranslation("roles.RoleManagement.modal.editRoleTitle")
            : getTranslation("roles.RoleManagement.modal.createRoleTitle")
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={getTranslation("roles.RoleManagement.modal.nameLabel")}
            rules={[
              {
                required: true,
                message: getTranslation("roles.RoleManagement.modal.nameError"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={getTranslation(
              "roles.RoleManagement.modal.descriptionLabel"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "roles.RoleManagement.modal.descriptionError"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </GlobalModal>
      <GlobalDeleteModal
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        loading={loading}
        requiredPermission="canUseRolesDelete"
        title={getTranslation(
          "roles.RoleManagement.modal.confirmDeletionTitle"
        )}
      />
    </div>
  );
};

export default withAuthorization(RoleManagement, "canUseRolesList");
