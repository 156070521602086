// src/services/googleSheetsService.js

import apiClient from "./index";

// Initiates the Google OAuth2 flow
export const initiateGoogleAuth = async (orgId) => {
  const response = await apiClient.get(`/google-sheets/google/auth?orgId=${orgId}`);
  return response;
};

export const getGoogleSheets = async (orgId) => {
  const response = await apiClient.get(`/google-sheets/google/sheets?orgId=${orgId}`);
  return response;
};

// Adds a row to the Google Sheet
export const addRowToGoogleSheet = async ({
  sheetId,
  phone,
  newName,
  newEmail,
  newGender,
}) => {
  const response = await apiClient.post(
    `/google-sheets/google/sheets/add-row`,
    {
      sheetId,
      phone,
      name: newName,
      email: newEmail,
      gender: newGender,
    }
  );
  return response.data;
};

export const addHeadersToGoogleSheet = async ({ sheetId, headers }) => {
  let reqBody  = {
    sheetId,
    headers: headers || ["name", "email", "phone", "gender","address","test"],
  }

  const response = await apiClient.post(
    `/google-sheets/google/sheets/add-headers`,
    reqBody
  );
  return response.data;
};

// Updates a row in the Google Sheet based on phone number
export const updateGoogleSheetByPhone = async ({
  sheetId,
  phone,
  newName,
  newEmail,
  newGender,
}) => {
  const reqBody = {
    sheetId,
    phone,
    key: "name",
    value: newName,
  };

  const response = await apiClient.post(
    `/google-sheets/google/sheets/update-by-phone`,
    reqBody
  );
  return response.data;
};

// Fetches all Google Sheets for the authenticated user
export const fetchGoogleSheets = async (userId) => {
  const response = await apiClient.get(`/google-sheets/google/sheets?userId=${userId}`);
  return response.data;
};
