import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Typography, Checkbox, Modal } from "antd";
import { fetchActions } from "../../api/actionApi";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const { Title } = Typography;

const Actions = () => {
  const { getTranslation } = useLocalization();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const { userData } = useAuth();

  const allColumns = [
    {
      title: getTranslation("actions.columns.name"),
      dataIndex: ["config", "name"],
      key: "name",
    },
    {
      title: getTranslation("actions.columns.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: getTranslation("actions.columns.description"),
      dataIndex: ["config", "description"],
      key: "description",
    },
    {
      title: getTranslation("actions.columns.phoneNumber"),
      dataIndex: ["config", "phoneNumber"],
      key: "phoneNumber",
      render: (text, record) =>
        record.type === "action_transfer_call" ? text : "",
    },
    {
      title: getTranslation("actions.columns.inputSchema"),
      key: "inputSchema",
      render: (text, record) =>
        record.type === "action_external" ? (
          <ul>
            {record.config.inputSchema.properties &&
              Object.keys(record.config.inputSchema.properties).map((key) => (
                <li key={key}>
                  {key}: {record.config.inputSchema.properties[key].type}
                </li>
              ))}
          </ul>
        ) : (
          ""
        ),
    },
  ];

  useEffect(() => {
    const storedColumns = localStorage.getItem("selectedActionColumns");
    if (storedColumns) {
      setSelectedColumns(JSON.parse(storedColumns));
    } else {
      setSelectedColumns(allColumns.map((col) => col.key));
    }
    getActionsData(page, pageSize);
  }, [page, pageSize]);

  const getActionsData = async (page, pageSize) => {
    try {
      setLoading(true);
      const response = await fetchActions(page, pageSize, userData?.orgId);
      setActions(response.items);
      setTotal(response.total);
      setLoading(false);
    } catch (error) {
      console.error(getTranslation("actions.error.fetchActions"), error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleEditColumns = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    localStorage.setItem(
      "selectedActionColumns",
      JSON.stringify(selectedColumns)
    );
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const columns = allColumns.filter((col) => selectedColumns.includes(col.key));

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="flex items-center justify-between mb-4">
        <Title level={2}>{getTranslation("actions.title")}</Title>
        <div>
          <Button
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={handleEditColumns}
          >
            {getTranslation("actions.editColumnsButton")}
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={actions}
          rowKey="id"
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
          className="bg-white p-4 rounded-lg"
        />
      )}
      <Modal
        title={getTranslation("actions.modalTitle")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          value={selectedColumns}
          onChange={handleCheckboxChange}
        >
          {allColumns.map((col) => (
            <Checkbox key={col.key} value={col.key}>
              {col.title}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal>
    </div>
  );
};

export default Actions;
