import { Button, notification } from "antd";
import { useAuth } from "../../AuthContext";
import { initiateGoogleAuth } from "../../api/googleSheetsService";
import { useLocalization } from "../../context/LocalizationContext";

export const Integrations = () => {
  const {
    userData: { orgId },
    organization,
  } = useAuth();

  const { isHubspotIntegrated } = organization;
  const { getTranslation } = useLocalization();

  let isGoogleSheetIntegrated = organization?.isGoogleSheetIntegrated;

  const handleAuth = async () => {
    if (isGoogleSheetIntegrated) return;
    try {
      const response = await initiateGoogleAuth(orgId);
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error("Error during Google authentication:", error);
      notification.error({
        message: getTranslation("settings.Integrations.error.title"),
        description: getTranslation("settings.Integrations.error.description"),
      });
    }
  };

  return (
    <div className="mt-84 flex justify-center items-center bg-white h-screen min-h-screen">
      <div className="flex flex-col px-[28px] py-[30px] gap-[30px] border w-3/4 h-3/4 rounded-sm">
        <section>
          <h1 className="text-xl font-bold">
            {getTranslation("settings.Integrations.title")}
          </h1>
          <p className="text-sm">
            {getTranslation("settings.Integrations.description")}
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold border-b">
            {getTranslation("settings.Integrations.crm_section")}
          </h2>
          <div className="mt-4 flex flex-wrap w-full">
            <div
              className={`flex flex-col gap-2 w-1/2 p-[24px] border rounded-md ${
                isGoogleSheetIntegrated && "border-green-600 "
              }`}
            >
              <h3 className="flex font-semibold items-center gap-2">
                <span>
                  <img
                    src="/assets/integrations/hubspot-logo.png"
                    height={24}
                    width={24}
                    alt="hubspot-logo"
                  />
                </span>
                {getTranslation("settings.Integrations.hubspot.title")}
              </h3>
              <p className="text-sm">
                {getTranslation("settings.Integrations.hubspot.description")}
              </p>
              <div>
                <Button
                  size="large"
                  className={
                    isHubspotIntegrated &&
                    "border border-green-600 text-green-600 font-semibold"
                  }
                  href={
                    !isHubspotIntegrated &&
                    `${process.env.REACT_APP_SERVER_URL}/integrations/connect/hubspot`
                  }
                >
                  {isHubspotIntegrated
                    ? getTranslation(
                        "settings.Integrations.hubspot.connected_button"
                      )
                    : getTranslation(
                        "settings.Integrations.hubspot.connect_button"
                      )}
                </Button>
              </div>
            </div>
          </div>

          <div className={`mt-4 flex flex-wrap w-full`}>
            <div
              className={`flex flex-col gap-2 w-1/2 p-[24px] border ${
                isGoogleSheetIntegrated && "border-green-600 "
              } rounded-md`}
            >
              <h3 className="flex font-semibold items-center gap-2">
                <span>
                  <img
                    src="/assets/integrations/google_sheets_icon.png"
                    height={24}
                    width={24}
                    alt="google-sheet-logo"
                  />
                </span>
                {getTranslation("settings.Integrations.google_sheet.title")}
              </h3>
              <p className="text-sm">
                {getTranslation(
                  "settings.Integrations.google_sheet.description"
                )}
              </p>
              <div>
                <Button
                  size="large"
                  className={
                    isGoogleSheetIntegrated &&
                    "border border-green-600 text-green-600 font-semibold"
                  }
                  onClick={handleAuth}
                >
                  {isGoogleSheetIntegrated
                    ? getTranslation(
                        "settings.Integrations.google_sheet.connected_button"
                      )
                    : getTranslation(
                        "settings.Integrations.google_sheet.connect_button"
                      )}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
