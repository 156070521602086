// NotAuthorizedPage.js
import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const NotAuthorizedPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { getTranslation } = useLocalization();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="not-authorized-container">
      <div className="not-authorized-content">
        <h1>{getTranslation("authentication.unauthorized.message")}</h1>
        <div className="mt-4">
          <Button type="primary" onClick={handleGoBack}>
            {getTranslation("authentication.unauthorized.goBackButton")}
          </Button>

          <Button
            type="primary"
            danger
            onClick={() => logout()}
            className="ml-10"
          >
            {getTranslation("authentication.logout.logoutButton")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .not-authorized-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          z-index: 9999;
        }
        .not-authorized-content {
          text-align: center;
          background: white;
          padding: 20px;
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};

export default NotAuthorizedPage;
