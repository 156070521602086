// moment
import moment from "moment";

// antd
import { Card, DatePicker, message, Typography, Spin } from "antd";

// react
import React, { useEffect, useState } from "react";

// components
import CallsChart from "../chatt/CallChart";
import AgentStats from "../chatt/AgentStats";

// firebase
import { firestore } from "../../firebaseConfig";
import { collection, getDocs, query as Query, where } from "firebase/firestore";

// campaigns
import Campaigns from "../campaigns/Campaigns";

// AuthContext
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

// react-router-dom
import { useLocation } from "react-router-dom";

// axios
import axios from "axios";

const { RangePicker } = DatePicker;
const { Title, Paragraph } = Typography;

export default function Dashboard() {
  const { getTranslation } = useLocalization(); // Use the localization hook
  const [callsData, setCallsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const [loading, setLoading] = useState(true);

  const { userData, organization } = useAuth();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const urlQuery = useQuery();
  const code = urlQuery.get("code");

  const orgId = organization?.id;
  /**
   * Handles the after math of OAuth flow for Hubspot integration.
   *
   * @description
   * This effect runs when the user completes the OAuth flow of Hubspot and is redirected back to the Leadsmotion client.
   * The redirect includes an authentication code ('code'), which is sent to the backend endpoint
   * `/integrations/hubspot/token` to obtain the `access_token`, `refresh_token`, and token expiry.
   * These values are then stored in Firestore by the backend.
   *
   * @param {string} code - The authorization code returned by Hubspot after the OAuth flow.
   * @param {string} userId - The user ID to associate with the OAuth integration.
   */
  useEffect(() => {
    if (code) {
      (async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/integrations/hubspot/token`,
            {
              code,
              orgId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(
            "Error fetching token:",
            error.response?.data || error.message
          );
        }
      })();
    }
  }, [code, orgId]);

  useEffect(() => {
    fetchCallsData();
  }, []);

  const fetchCallsData = async () => {
    try {
      const orgId = userData?.orgId;
      // Create a query with a where clause to filter by orgId
      const callsQuery = Query(
        collection(firestore, "calls"),
        where("orgId", "==", orgId)
      );

      // Execute the query
      const callsSnapshot = await getDocs(callsQuery);

      const fetchedCalls = callsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          key: doc.id,
          startTime: data.startTime
            ? new Date(data.startTime.seconds * 1000)
            : null,
          endTime: data.endTime ? new Date(data.endTime.seconds * 1000) : null,
        };
      });

      const validCalls = fetchedCalls.filter(
        (call) => call.startTime && call.endTime
      );
      setCallsData(validCalls);
      setFilteredData(validCalls);
    } catch (error) {
      message.error(getTranslation("dashboard.errorFetchingData"));
      console.error(getTranslation("dashboard.errorFetchingData"), error);
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    const filtered = callsData?.filter((call) => {
      const callDate = moment(call.startTime);
      return callDate.isBetween(dateRange[0], dateRange[1], null, "[]");
    });
    setFilteredData(filtered);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handleSubmit = () => {
    filterData();
  };

  return (
    <div className="p-6">
      <Title level={2}>{getTranslation("dashboard.title")}</Title>
      <Paragraph>{getTranslation("dashboard.description")}</Paragraph>

      {/* <div className="mb-4 flex items-center space-x-4">
        <RangePicker
          value={dateRange}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          disabledDate={current => current && current > moment().endOf('day')}
        />
        <Button type="primary" onClick={handleSubmit}>
          {getTranslation("dashboard.submit")}
        </Button>
      </div> */}

      <div className="flex justify-between ">
        <Card className="w-[70%]">
          {loading ? <Spin /> : <CallsChart data={filteredData} />}
        </Card>
        <Card className="w-[28%]">
          {loading ? <Spin /> : <AgentStats data={filteredData} />}
        </Card>
      </div>
    </div>
  );
}
