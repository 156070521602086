import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Space,
  Input,
  Tabs,
  Tag,
  Dropdown,
  Menu,
  message,
  Drawer,
  Form,
  Select,
  Modal,
  List,
  Descriptions,
  Breadcrumb,
  Divider,
} from "antd";
import {
  UserAddOutlined,
  DeleteOutlined,
  ImportOutlined,
  EllipsisOutlined,
  PhoneOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { createLead, updateLead, deleteLead } from "../../api/leadApi";
import { createNewCall } from "../../api/callApi";
import CSVUploadComponent from "../CSVUploader";
import CreateLeadForm from "./CreateLeadForm";
import { fetchAgentByOrgId } from "../../api/agentApi";
import { firestore } from "../../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import GlobalModal from "../../components/GlobalModal";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import { statusData } from "../../data/statusData";
import { fetchPhoneNumbers } from "../../api/phoneNumbers";
import ImportPhoneNumberModal from "../phoneNumber/ImportPhoneNumberModal";
import GenericTable from "../../components/GenericTable";
import plus_white from "../../assets/plus_white.png";
import { useLocalization } from "../../context/LocalizationContext";

const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Leads = () => {
  const { getTranslation } = useLocalization();
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [bulkDeleteModalVisible, setBulkDeleteModalVisible] = useState(false);
  const [isCreateLeadModalVisible, setIsCreateLeadModalVisible] =
    useState(false);
  const [editLead, setEditLead] = useState(null);
  const [statusUpdateModalVisible, setStatusUpdateModalVisible] =
    useState(false);
  const [leadToUpdate, setLeadToUpdate] = useState(null);
  const [statusToUpdate, setStatusToUpdate] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [form] = Form.useForm();
  const [statusForm] = Form.useForm();
  const [collectedData, setCollectedData] = useState(null);
  const { organization, userData } = useAuth();
  const [isDeleted, setIsDeleted] = useState(false);
  const [numbers, setNumbers] = useState();
  const [selectedNumber, setSelectedNumber] = useState("");

  const navigate = useNavigate();

  const fetchLeadsLocal = async () => {
    try {
      const leadsQuery = query(
        collection(firestore, "orgLeads"),
        where("orgId", "==", userData?.orgId)
      );
      const leadsSnapshot = await getDocs(leadsQuery);
      const leads = leadsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          key: doc.id,
          ...data,
        };
      });

      setLeads(leads);
      setFilteredLeads(leads); // Initially display all leads
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(getTranslation("organization.Leads.lead_fetch_failure"));
      console.error("Failed to fetch leads:", error);
    }
  };

  const onLeadsChange = (updatedLeads) => {
    setLeads(updatedLeads);
    setFilteredLeads(updatedLeads); // Update filtered leads
  };

  useEffect(() => {
    fetchLeadsLocal();
  }, []);

  const handleStatusChange = () => {};

  const fetchAgentsLocal = async () => {
    try {
      const agentsData = await fetchAgentByOrgId(userData.orgId);

      setAgents(agentsData);

      if (agentsData?.length > 0) {
        setSelectedAgent(agentsData[0]?.agentResponse?.id);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch agents:", error);
    }
  };

  const fetchNumbersLocal = async () => {
    try {
      const numbersData = await fetchPhoneNumbers(userData.orgId);
      setNumbers(numbersData);
      console.log(JSON.stringify(numbersData));
      if (numbersData?.length > 0) {
        setSelectedNumber(numbersData[0].id);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch agents:", error);
    }
  };

  useEffect(() => {
    fetchNumbersLocal();
    fetchAgentsLocal();
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    try {
      await Promise.all(selectedRowKeys.map((id) => deleteLead(id)));
      const updatedLeads = leads.filter(
        (lead) => !selectedRowKeys.includes(lead.key)
      );
      onLeadsChange(updatedLeads);
      setFilteredLeads(updatedLeads);
      setSelectedRowKeys([]);
      message.success(
        getTranslation("organization.Leads.confirm_bulk_delete_success")
      );
    } catch (error) {
      message.error(
        getTranslation("organization.Leads.confirm_bulk_delete_failure")
      );
      console.error("Failed to delete leads:", error);
    }
    setLoading(false);
    setBulkDeleteModalVisible(false);
  };

  const handleDelete = async (key) => {
    setLoading(true);
    try {
      await deleteLead(key);
      const updatedLeads = leads.filter((lead) => lead.key !== key);
      onLeadsChange(updatedLeads);
      setFilteredLeads(updatedLeads);
      message.success(
        getTranslation("organization.Leads.lead_deleted_success")
      );
    } catch (error) {
      message.error(getTranslation("organization.Leads.lead_deleted_failure"));
      console.error("Failed to delete lead:", error);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    const filteredData = leads?.filter((lead) =>
      lead.name?.toLowerCase().includes(value?.toLowerCase())
    );
    setFilteredLeads(filteredData);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const showBulkDeleteModal = () => {
    setBulkDeleteModalVisible(true);
  };

  const closeBulkDeleteModal = () => {
    setBulkDeleteModalVisible(false);
  };

  const handleTabChange = (key) => {
    if (key === "all") {
      setFilteredLeads(leads);
    } else {
      const filteredData = leads.filter((lead) => lead.status === key);
      setFilteredLeads(filteredData);
    }
  };

  const handleConfirmStatusUpdate = async () => {
    const updatedLeads = [...leads];
    const leadIndex = updatedLeads.findIndex(
      (lead) => lead.id === leadToUpdate.id
    );
    if (leadIndex !== -1) {
      updatedLeads[leadIndex].status = statusToUpdate;

      try {
        await updateLead(leadToUpdate.id, { status: statusToUpdate });

        onLeadsChange(updatedLeads);
        setLeadToUpdate(null);
        setStatusToUpdate("");
        message.success(
          getTranslation("organization.Leads.confirm_status_change_success")
        );
      } catch (error) {
        message.error(
          getTranslation("organization.Leads.confirm_status_change_failure")
        );
        console.error("Failed to update lead status on the server:", error);
      }
    }
  };

  const handleCreateLead = async (values) => {
    if (!userData?.orgId) {
      message.error(
        getTranslation("organization.Leads.not_authorized_message")
      );
      return;
    }
    setLoading(true);
    try {
      await createLead({ ...values, orgId: userData?.orgId });
      message.success(
        getTranslation("organization.Leads.lead_created_success")
      );
      fetchLeadsLocal();
      setIsCreateLeadModalVisible(false);
    } catch (error) {
      message.error(getTranslation("organization.Leads.lead_created_failure"));
      console.error("Failed to create lead:", error);
    }
    setLoading(false);
  };

  const handleEditLead = async (values) => {
    setLoading(true);
    try {
      await updateLead(editLead.key, values);
      setIsCreateLeadModalVisible(false);
      fetchLeadsLocal();
      setLoading(false);
      setEditLead(null);
      message.success(
        getTranslation("organization.Leads.lead_updated_success")
      );
    } catch (error) {
      message.error(getTranslation("organization.Leads.lead_updated_failure"));
      console.error("Failed to update lead:", error);
    }
    setLoading(false);
  };

  const handleDeleteLead = async (leadId) => {
    setLoading(true);
    try {
      await deleteLead(leadId);
      
      const updatedLeads = leads.filter((lead) => lead.key !== leadId);
      onLeadsChange(updatedLeads);
      message.success(
        getTranslation("organization.Leads.lead_deleted_success")
      );
      setIsDeleted(false)
    } catch (error) {
      message.error(getTranslation("organization.Leads.lead_deleted_failure"));
      console.error("Failed to delete lead:", error);
    }
    setLoading(false);
  };

  const handleChangeMultipleStatus = () => {
    if (selectedRowKeys.length === 0) {
      message.error(getTranslation("organization.Leads.no_leads_selected"));
      return;
    }
    setStatusUpdateModalVisible(true);
  };

  const confirmChangeMultipleStatus = async () => {
    setLoading(true);
    try {
      const updatedLeads = leads.map((lead) =>
        selectedRowKeys.includes(lead.id)
          ? { ...lead, status: statusToUpdate }
          : lead
      );
      onLeadsChange(updatedLeads);
      setStatusUpdateModalVisible(false);
      setSelectedRowKeys([]);
      setStatusToUpdate("");
      setLoading(false);
      message.success(
        getTranslation("organization.Leads.confirm_status_change_success")
      );
    } catch (error) {
      message.error(
        getTranslation("organization.Leads.confirm_status_change_failure")
      );
      console.error("Failed to update lead statuses:", error);
      setLoading(false);
    }
  };

  const callAPI = async () => {
    const credRemain = organization?.leftoverMinutes;
    if (credRemain < 1) {
      message.error(
        getTranslation("organization.Leads.call_api_insufficient_balance")
      );
      navigate("/pricing");
      return;
    }

    if (!agents.length || !selectedAgent) {
      message.error(getTranslation("organization.Leads.call_api_no_agents"));
      return;
    }
    setLoading(true);
    for (const leadId of selectedRowKeys) {
      const leadIndex = leads.findIndex((lead) => lead.key === leadId);
      if (leadIndex !== -1) {
        const updatedLeads = [...leads];
        updatedLeads[leadIndex].status = "Processing...";
        onLeadsChange(updatedLeads);

        try {
          const toNumber = `${updatedLeads[leadIndex].phone}`;
          const leadId = `${updatedLeads[leadIndex].key}`;

          // const selectedNumberData = numbers.find(
          //   (num) => num.id === selectedNumber
          // );

          await createNewCall({
            toNumber,
            selectedAgent,
            phoneNumberId: selectedNumber,
            user: userData,
            leadId,
          });

          updatedLeads[leadIndex].status = "successful_call";
          console.log(
            `API call successful for lead ${updatedLeads[leadIndex].name}`
          );
        } catch (error) {
          updatedLeads[leadIndex].status = "Failed";
          console.error(
            `Failed to call API for lead ${updatedLeads[leadIndex].name}:`,
            error
          );
        }

        onLeadsChange(updatedLeads);
      }
    }
    setLoading(false);
  };

  const handleExport = () => {
    const csvData = filteredLeads.map((lead) => ({
      name: lead.name,
      email: lead.email,
      phone: lead.phone,
      status: lead.status,
    }));

    const csvString = [
      ["Name", "Email", "Phone", "Status"],
      ...csvData.map((item) => [
        item.name,
        item.email,
        item.phone,
        item.status,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leads.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const columns = [
    {
      title: getTranslation("organization.Leads.name_column"),
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: getTranslation("organization.Leads.phone_column"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: getTranslation("organization.Leads.status_column"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "default";
        if (status === "appointment_booked") color = "green";
        else if (status === "called") color = "blue";
        else if (status === "not_started") color = "red";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    // {
    //   title: getTranslation("organization.Leads.collected_data_column"),
    //   dataIndex: "collectedData",
    //   key: "collectedData",
    //   render: (text, record) =>
    //     record?.collectedData ? (
    //       <Button onClick={() => handleViewCollectedData(record)}>
    //         {getTranslation("organization.Leads.view_collected_data_button")}
    //       </Button>
    //     ) : null,
    // },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <>
          <GlobalDeleteModal
            visible={isDeleted}
            onOk={() => handleDeleteLead(record.key)}
            onCancel={() => setIsDeleted(false)}
            loading={loading}
            requiredPermission="canDeleteLeads"
          />

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={() => {
                    setEditLead(record);
                    setIsCreateLeadModalVisible(true);
                  }}
                >
                  {getTranslation("organization.Leads.edit_menu_item")}
                </Menu.Item>

                <Menu.Item key="2" onClick={() => setIsDeleted(true)}>
                  {getTranslation("organization.Leads.delete_menu_item")}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleViewCollectedData = (record) => {
    setCollectedData(record?.collectedData);
    showDrawer();
  };

  return (
    <div
      className={`min-h-screen bg-white ${
        modalVisible ? "blurry-background" : ""
      }`}
    >
      <div className="flex items-center justify-between px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation("organization.Leads.title_outgoing_calls"),
            },
            {
              title: getTranslation("organization.Leads.title_contacts"),
            },
          ]}
        />

        <Space>
          <Button
            danger
            className="rounded-lg"
            icon={<DeleteOutlined />}
            onClick={showBulkDeleteModal}
            disabled={selectedRowKeys.length === 0}
          >
            {getTranslation("organization.Leads.bulk_delete_button")}
          </Button>
          <Button
            className="rounded-lg"
            icon={<ImportOutlined />}
            onClick={showModal}
          >
            {getTranslation("organization.Leads.import_csv_button")}
          </Button>
          <Button
            className="bg-black text-white flex items-center"
            onClick={() => setIsCreateLeadModalVisible(true)}
          >
            <img src={plus_white} className="w-4 h-4 object-contain mr-1" />
            {getTranslation("organization.Leads.create_user_button")}
          </Button>
        </Space>
      </div>

      <Divider />

      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          {/* <Search
            placeholder="Search"
            onSearch={handleSearch}
            style={{ width: 200 }}
            onChange={(e) => {
              if (e.target.value?.length < 2) {
                setFilteredLeads(leads);
              }
            }}
          /> */}
          <div />
          <Space>
            <Select
              placeholder={getTranslation(
                "organization.Leads.select_agent_placeholder"
              )}
              style={{ width: 200 }}
              onChange={(value) => setSelectedAgent(value)}
              value={selectedAgent}
            >
              {agents.map((agent) => (
                <Option
                  key={agent.agentResponse.id}
                  value={agent.agentResponse.id}
                >
                  {agent.agentResponse.name}
                </Option>
              ))}
            </Select>
            {numbers && (
              <>
                {numbers?.length > 0 ? (
                  <Select
                    placeholder={getTranslation(
                      "organization.Leads.select_number_placeholder"
                    )}
                    style={{ width: 200 }}
                    onChange={(value) => setSelectedNumber(value)}
                    value={selectedNumber}
                  >
                    {numbers.map((number) => (
                      <Option key={number.id} value={number.id}>
                        {number.name} ({number?.number})
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <>
                    <ImportPhoneNumberModal fetchNumbers={fetchNumbersLocal} />
                  </>
                )}
              </>
            )}
            <Button
              icon={<PhoneOutlined />}
              onClick={callAPI}
              disabled={
                selectedRowKeys.length === 0 ||
                !selectedAgent ||
                !selectedNumber
              }
            >
              {getTranslation("organization.Leads.call_now_button")}
            </Button>
          </Space>
        </div>
        <Tabs
          defaultActiveKey="all"
          className="custom-tabs"
          onChange={handleTabChange}
        >
          <TabPane tab={getTranslation("organization.Leads.all_tab")} key="all">
            {/* <GenericTable
              columns={columns}
              data={filteredLeads}
              loading={loading}
            /> */}
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredLeads}
              loading={loading}
              pagination={{
                pageSize,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50", leads?.length],
                onChange: handlePageSizeChange,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              className="bg-white p-4 rounded-lg border"
            />
          </TabPane>
          {statusData.map((status) => (
            <TabPane tab={status.label} key={status.value}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={filteredLeads.filter(
                  (lead) => lead.status === status.value
                )}
                loading={loading}
                pagination={{
                  pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "5",
                    "10",
                    "20",
                    "50",
                    "100",
                    statusData.length,
                  ],
                  onChange: handlePageSizeChange,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                className="bg-white p-4 rounded-lg border"
              />
            </TabPane>
          ))}
        </Tabs>
        <GlobalModal
          title={getTranslation("organization.Leads.confirm_bulk_delete_title")}
          visible={modalVisible}
          onCancel={closeModal}
          noFooter
          bodyStyle={{ backdropFilter: "blur(10px)" }}
          requiredPermission={"canCreateBulkLeads"}
        >
          <CSVUploadComponent />
        </GlobalModal>
        <GlobalModal
          title={getTranslation("organization.Leads.confirm_bulk_delete_title")}
          visible={bulkDeleteModalVisible}
          onCancel={closeBulkDeleteModal}
          onOk={handleBulkDelete}
          okText={getTranslation("organization.Leads.confirm_bulk_delete_ok")}
          okButtonProps={{ danger: true }}
          cancelText={getTranslation(
            "organization.Leads.confirm_bulk_delete_cancel"
          )}
          requiredPermission="canDeleteBulkLeads"
          loading={loading}
        >
          <List
            dataSource={selectedRowKeys.map((key) =>
              filteredLeads.find((lead) => lead?.key === key)
            )}
            renderItem={(lead) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() =>
                      setSelectedRowKeys(
                        selectedRowKeys.filter((k) => k !== lead.key)
                      )
                    }
                  >
                    {getTranslation(
                      "organization.Leads.confirm_bulk_delete_cancel"
                    )}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={lead.name}
                  description={`${lead.phone}`}
                />
              </List.Item>
            )}
          />
        </GlobalModal>
        <Modal
          title={getTranslation(
            "organization.Leads.confirm_status_change_title"
          )}
          visible={statusUpdateModalVisible}
          onCancel={() => setStatusUpdateModalVisible(false)}
          onOk={() => statusForm.submit()}
          okText={getTranslation("organization.Leads.confirm_status_change_ok")}
          cancelText={getTranslation(
            "organization.Leads.confirm_status_change_cancel"
          )}
        >
          <Form
            form={statusForm}
            layout="vertical"
            onFinish={handleConfirmStatusUpdate}
          >
            <Form.Item
              name="status"
              label={getTranslation("organization.Leads.status_column")}
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select
                placeholder={getTranslation("organization.Leads.status_column")}
              >
                {statusData.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
          <List
            dataSource={selectedRowKeys.map((key) =>
              filteredLeads.find((lead) => lead?.key === key)
            )}
            renderItem={(lead) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() =>
                      setSelectedRowKeys(
                        selectedRowKeys.filter((k) => k !== lead.key)
                      )
                    }
                  >
                    {getTranslation(
                      "organization.Leads.confirm_bulk_delete_cancel"
                    )}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={lead.name}
                  description={`Email: ${lead.email}, Phone: ${lead.phone}`}
                />
              </List.Item>
            )}
          />
        </Modal>
        <CreateLeadForm
          visible={isCreateLeadModalVisible}
          onCreate={editLead ? handleEditLead : handleCreateLead}
          onCancel={() => {
            setIsCreateLeadModalVisible(false);
            setEditLead(null);
          }}
          loading={loading}
          editLead={editLead}
        />
        <Drawer
          title={getTranslation(
            "organization.Leads.collected_data_drawer_title"
          )}
          placement="right"
          onClose={closeDrawer}
          visible={drawerVisible}
          width={400}
        >
          {collectedData ? (
            <Descriptions bordered column={1}>
              {Object.keys(collectedData).map((key) => (
                <Descriptions.Item label={key} key={key}>
                  {typeof collectedData[key] === "boolean"
                    ? collectedData[key]
                      ? "Yes"
                      : "No"
                    : collectedData[key]}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ) : (
            <p>{getTranslation("organization.Leads.no_collected_data")}</p>
          )}
        </Drawer>
      </div>
    </div>
  );
};

export default Leads;
