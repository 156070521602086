import React, { useState } from "react";
import { Input, Button, Radio, Switch, Typography } from "antd";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../../context/LocalizationContext";

const { TextArea } = Input;
const { Title, Text } = Typography;

const CreateSequence = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [availability, setAvailability] = useState("everyone");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { getTranslation } = useLocalization();

  const handleSave = () => {
    const newErrors = {};
    if (!name)
      newErrors.name = getTranslation(
        "sequence.CreateSequence.invalid_process_name"
      );
    if (!description)
      newErrors.description = getTranslation(
        "sequence.CreateSequence.invalid_process_description"
      );

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle save logic here
      console.log("Saving sequence:", { name, description, availability });
      navigate("/next-step");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center cursor-pointer text-xl font-medium">
            <LeftOutlined className="mr-2" />{" "}
            {getTranslation("sequence.CreateSequence.processes")}
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-gray-500">
              {getTranslation("sequence.CreateSequence.last_updated")}
            </div>
            <div className="text-gray-500">...</div>
            <Switch
              checkedChildren={getTranslation("sequence.CreateSequence.active")}
              unCheckedChildren={getTranslation(
                "sequence.CreateSequence.inactive"
              )}
            />
            <Button>
              {getTranslation("sequence.CreateSequence.test_sequence")}
            </Button>
            <Button
              type="primary"
              onClick={handleSave}
              disabled={!name || !description}
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
            >
              {getTranslation("sequence.CreateSequence.save")}
            </Button>
          </div>
        </div>

        <div className="bg-white border rounded-md p-8 mb-8">
          <div className="flex items-center mb-4">
            <InfoCircleOutlined style={{ fontSize: "24px" }} className="mr-2" />
            <Title level={4} className="m-0">
              {getTranslation("sequence.CreateSequence.identify_sequence")}
            </Title>
          </div>
          <Text>
            {getTranslation("sequence.CreateSequence.describe_sequence")}
          </Text>
          <div className="mt-4">
            <div className="mb-4">
              <Text className="block mb-2">
                {getTranslation("sequence.CreateSequence.name")}
              </Text>
              <Input
                placeholder={getTranslation(
                  "sequence.CreateSequence.name_placeholder"
                )}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-1"
              />
              {errors.name && <Text type="danger">{errors.name}</Text>}
            </div>
            <div className="mb-4">
              <Text className="block mb-2">
                {getTranslation("sequence.CreateSequence.describe_use")}
              </Text>
              <TextArea
                rows={4}
                placeholder={getTranslation(
                  "sequence.CreateSequence.describe_use_placeholder"
                )}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mb-1"
              />
              {errors.description && (
                <Text type="danger">{errors.description}</Text>
              )}
            </div>
            <div className="mb-4">
              <Text className="block mb-2">
                {getTranslation("sequence.CreateSequence.set_availability")}
              </Text>
              <Radio.Group
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
              >
                <Radio value="everyone">
                  {getTranslation("sequence.CreateSequence.everyone")}
                </Radio>
                <Radio value="rules">
                  {getTranslation("sequence.CreateSequence.rules")}
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="bg-white border rounded-md p-8">
          <div className="flex items-center mb-4">
            <div style={{ fontSize: "24px" }} className="mr-2">
              &#128221;
            </div>
            <Title level={4} className="m-0">
              {getTranslation("sequence.CreateSequence.build_sequence")}
            </Title>
          </div>
          <Text>
            {getTranslation(
              "sequence.CreateSequence.build_sequence_description"
            )}
          </Text>
          <div className="mt-8 border-dashed border-2 border-gray-300 p-16 text-gray-500">
            {getTranslation("sequence.CreateSequence.start_building")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSequence;
