import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  message,
  Select,
  Switch,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../AuthContext";
import { useParams } from "react-router-dom";
import {
  createMember,
  deleteMember,
  fetchMembersByOrgId,
} from "../../api/firebase/orgMemberApi";
import { createUser, updateUser } from "../../api/userApi";
import { fetchPermissions } from "../../api/firebase/permissionApi";
import { getAllRoles } from "../../api/firebase/roleApi";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import GlobalModal from "../../components/GlobalModal";
import { useLocalization } from "../../context/LocalizationContext";

const { Option } = Select;

const OrgMemberManagement = () => {
  const [members, setMembers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const [deleteMemberId, setDeleteMemberId] = useState(null);
  const [memberForm] = Form.useForm();
  const { user } = useAuth();
  const { id } = useParams();
  const { getTranslation } = useLocalization();

  useEffect(() => {
    loadMembers();
    loadPermissions();
    loadRoles();
  }, []);

  const loadMembers = async () => {
    setLoading(true);
    try {
      const members = await fetchMembersByOrgId(id);
      setMembers(members);
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation("organization.OrgMemberManagement.fetch_members_failure")
      );
      setLoading(false);
    }
  };

  const loadPermissions = async () => {
    setLoading(true);
    try {
      const permissions = await fetchPermissions();
      setPermissions(permissions);
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.OrgMemberManagement.fetch_permissions_failure"
        )
      );
      setLoading(false);
    }
  };

  const loadRoles = async () => {
    setLoading(true);
    try {
      const roles = await getAllRoles();
      setRoles(roles);
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation("organization.OrgMemberManagement.fetch_roles_failure")
      );
      setLoading(false);
    }
  };

  const handleMemberSubmit = async (values) => {
    setLoading(true);
    try {
      const selectedPermissions = permissions.filter((permission) =>
        values.permissions.includes(permission.name)
      );

      if (editMember) {
        await updateUser(editMember.id, {
          email: values.email,
          name: values.name,
          invitedBy: user.uid,
          roleId: values.role,
          permissions: selectedPermissions,
          enabled: values.enabled || false,
        });
        setEditMember(null);
        message.success(
          getTranslation(
            "organization.OrgMemberManagement.member_update_success"
          )
        );
      } else {
        await createUser({
          email: values.email,
          name: values.name,
          roleId: values.role,
          permissions: selectedPermissions,
          enabled: values.enabled || false,
          orgId: id,
          verified: true,
          isPasswordUpdated: false,
          invitedBy: user.uid,
          password: values.password,
        });

        setEditMember(null);

        message.success(
          getTranslation(
            "organization.OrgMemberManagement.member_create_success"
          )
        );
      }

      setIsMemberModalVisible(false);
      loadMembers();
    } catch (error) {
      console.error(
        getTranslation(
          "organization.OrgMemberManagement.member_creation_error"
        ),
        error
      );
      message.error(
        getTranslation("organization.OrgMemberManagement.member_save_failure")
      );
      setLoading(false);
    }
  };

  const handleDeleteMember = async (memberId) => {
    setLoading(true);
    try {
      await deleteMember(memberId);
      message.success(
        getTranslation("organization.OrgMemberManagement.member_delete_success")
      );
      loadMembers();
    } catch (error) {
      message.error(
        getTranslation("organization.OrgMemberManagement.member_delete_failure")
      );
      setLoading(false);
    }
  };

  const openMemberModal = (member) => {
    setEditMember(member);
    if (member) {
      memberForm.setFieldsValue({
        ...member,
        permissions: member?.permissions?.map((perm) => perm.name),
      });
    } else {
      memberForm.resetFields();
    }
    setIsMemberModalVisible(true);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center">
      <Card className="w-full p-8 mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">
            {getTranslation("organization.OrgMemberManagement.title")}
          </h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openMemberModal(null)}
          >
            {getTranslation(
              "organization.OrgMemberManagement.create_member_button"
            )}
          </Button>
        </div>
        <Table
          dataSource={members}
          columns={[
            {
              title: getTranslation(
                "organization.OrgMemberManagement.name_column"
              ),
              dataIndex: "name",
              key: "name",
            },
            {
              title: getTranslation(
                "organization.OrgMemberManagement.email_column"
              ),
              dataIndex: "email",
              key: "email",
            },
            {
              title: getTranslation(
                "organization.OrgMemberManagement.role_column"
              ),
              dataIndex: "roleId",
              key: "roleId",
            },
            {
              title: getTranslation(
                "organization.OrgMemberManagement.enabled_column"
              ),
              dataIndex: "enabled",
              key: "enabled",
              render: (enabled) =>
                enabled
                  ? getTranslation(
                      "organization.OrgMemberManagement.enabled_yes"
                    )
                  : getTranslation(
                      "organization.OrgMemberManagement.enabled_no"
                    ),
            },
            {
              title: getTranslation(
                "organization.OrgMemberManagement.actions_column"
              ),
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openMemberModal(record)}
                  >
                    {getTranslation(
                      "organization.OrgMemberManagement.edit_button"
                    )}
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setDeleteMemberId(record.id);
                      setIsDeleteModalVisible(true);
                    }}
                    danger
                  >
                    {getTranslation(
                      "organization.OrgMemberManagement.delete_button"
                    )}
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </Card>
      <GlobalModal
        visible={isMemberModalVisible}
        onOk={memberForm.submit}
        onCancel={() => {
          setIsMemberModalVisible(false);
          setEditMember(null);
          memberForm.resetFields();
        }}
        loading={loading}
        requiredPermission={editMember ? "canEditMembers" : "canCreateMembers"}
      >
        <Form
          form={memberForm}
          layout="vertical"
          onFinish={handleMemberSubmit}
          initialValues={editMember}
        >
          <Form.Item
            name="name"
            label={getTranslation(
              "organization.OrgMemberManagement.name_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "organization.OrgMemberManagement.name_required"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={getTranslation(
              "organization.OrgMemberManagement.email_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "organization.OrgMemberManagement.email_required"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!editMember && (
            <Form.Item
              name="password"
              label={getTranslation(
                "organization.OrgMemberManagement.password_label"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "organization.OrgMemberManagement.password_required"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label={getTranslation(
              "organization.OrgMemberManagement.role_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "organization.OrgMemberManagement.role_required"
                ),
              },
            ]}
          >
            <Select
              placeholder={getTranslation(
                "organization.OrgMemberManagement.role_placeholder"
              )}
            >
              {roles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permissions"
            label={getTranslation(
              "organization.OrgMemberManagement.permissions_label"
            )}
          >
            <Select
              mode="multiple"
              placeholder={getTranslation(
                "organization.OrgMemberManagement.permissions_placeholder"
              )}
            >
              {permissions.map((permission) => (
                <Option key={permission.name} value={permission.name}>
                  {permission.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="enabled"
            label={getTranslation(
              "organization.OrgMemberManagement.enabled_label"
            )}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </GlobalModal>
      <GlobalDeleteModal
        visible={isDeleteModalVisible}
        onOk={() => handleDeleteMember(deleteMemberId)}
        onCancel={() => setIsDeleteModalVisible(false)}
        loading={loading}
        requiredPermission="canDeleteMembers"
      />
    </div>
  );
};

export default OrgMemberManagement;
