// axios
import axios from "axios";

// react
import React, { useEffect, useState } from "react";

// ant-design
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  message,
  Modal,
  Checkbox,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

// components
import Label from "../../components/Label";

// components
import SeeAllActions from "../agents/SeeAllActions";
import { useLocalization } from "../../context/LocalizationContext";
import { getGoogleSheets } from "../../api/googleSheetsService";

// context
import { useAuth } from "../../AuthContext";

const { Option } = Select;

const ExternalActionForm = ({
  onChange,
  actions,
  setSelectedGoogleSheetID,
  selectedAgentData,
}) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const { getTranslation } = useLocalization();
  const [sheets, setSheets] = useState(null);
  const [selectedId, setSelectedSheetID] = useState(
    selectedAgentData?.selectedGoogleSheet || null
  );

  const { userData, organization } = useAuth();
  const { isHubspotIntegrated } = organization;

  const [hubspotFields, setHubspotFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleValuesChange = (_, allValues) => {
    if (onChange) {
      const actionData = {
        type: "function",
        function: {
          name: allValues?.name,
          async: false,
          parameters: {
            type: "object",
            properties: allValues.inputSchema?.reduce((acc, curr) => {
              acc[curr?.name] = { type: curr?.type };
              return acc;
            }, {}),
          },
        },
        server: {
          url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/during-call-processing`,
        },
      };

      onChange(actionData); // Send the updated action data to the parent component
    }
  };

  const fillTheForm = async (value) => {
    if (value?.function?.name?.includes(" ")) {
      message.error("Name should not contain spaces");
      return;
    }

    // Extract data and set the form values
    form.setFieldsValue({
      name: value?.function?.name,
      description: value?.function?.description,
      inputSchema: Object.entries(
        value?.function?.parameters?.properties || {}
      ).map(([name, schema]) => ({
        name,
        type: schema.type,
      })),
    });

    const actionData = {
      type: "function",
      destinations: value?.destinations,
      function: value?.function,
      server: {
        url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/during-call-processing`,
      },
    };

    onChange(actionData);
  };

  /**
   * Maps the Hubspot contact fields to the required property keys for creating actions.
   *
   * @function mapHubspotFields
   * @param {Object} contact - The Hubspot contact object containing various properties.
   * @param {Object} contact.properties - The properties of the Hubspot contact.
   * @returns {Array<string>} - An array of property keys that represent the required Hubspot contact fields for action creation.
   */
  const mapHubspotFields = (contact) => {
    const { properties } = contact;
    const fieldsToExclude = ["hs_object_id", "createdate", "lastmodifieddate"];
    return Object.keys(properties)
      .filter((key) => !fieldsToExclude.includes(key))
      .map((key) => ({
        name: key,
        type: determineFieldType(properties[key]),
      }));
  };

  /**
   * Determines the type of the provided value.
   *
   * @function determineFieldType
   * @param {*} value - The value whose type is to be determined.
   * @returns {string} - The type of the value, which can be 'string', 'number', 'boolean', or 'object'.
   */

  const determineFieldType = (value) => {
    if (Array.isArray(value)) return "array";
    if (value === null) return "null";
    switch (typeof value) {
      case "string":
        return "string";
      case "number":
        return "number";
      case "boolean":
        return "boolean";
      case "object":
        return "object";
      default:
        return "unknown";
    }
  };

  /**
   * Fetches Hubspot contacts associated with the Hubspot account of the user whose userId is provided.
   *
   * @function fetchHubspotContacts
   * @param {string} orgId - The ID of the org whose Hubspot account is to be queried.
   * @returns {Promise<Array>} - A promise that resolves to an array of Hubspot contacts.
   */

  const fetchHubspotContacts = async (orgId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/integrations/hubspot/contacts`,
        {
          params: { orgId },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const contacts = response.data.results;

      if (contacts.length > 0) {
        const fields = mapHubspotFields(contacts[0]);
        setHubspotFields(fields);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching HubSpot contacts:", error);
      setLoading(false);
    }
  };

  const handleApply = () => {
    const selectedFieldsData = hubspotFields.filter((field) =>
      selectedFields.includes(field.name)
    );

    form.setFieldsValue({
      inputSchema: selectedFieldsData,
    });
    setIsModalVisible(false);
  };

  const fetchSheets = async () => {
    try {
      const response = await getGoogleSheets(userData.orgId);
      setSheets(response.data);
    } catch (error) {
      setSheets([]);
    }
  };

  useEffect(() => {
    fetchSheets();
  }, []);

  useEffect(() => {
    setSelectedGoogleSheetID(selectedId);
  }, [selectedId]);

  return (
    <>
      <SeeAllActions
        fillTheForm={fillTheForm}
        show={show}
        setShow={setShow}
        actions={actions}
      />

      <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
        <Form.Item
          name="name"
          label={
            <Label>
              {getTranslation("actions.ExternalActionForm.action_name_label")}
            </Label>
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.ExternalActionForm.enter_action_name"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "actions.ExternalActionForm.enter_action_name"
            )}
          />
        </Form.Item>

        {/* 
            <Form.Item
            name="description"
            label={<Label>Action Description</Label>}
            rules={[{ required: true, message: "Please enter description" }]}
            >
            <Input placeholder="Enter action description" />
            </Form.Item> 
        */}

        <Form.List name="inputSchema">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    fieldKey={[fieldKey, "name"]}
                    rules={[
                      {
                        required: true,
                        message: getTranslation(
                          "actions.ExternalActionForm.enter_field_name"
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={getTranslation(
                        "actions.ExternalActionForm.field_name_placeholder"
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    rules={[
                      {
                        required: true,
                        message: getTranslation(
                          "actions.ExternalActionForm.select_field_type"
                        ),
                      },
                    ]}
                  >
                    <Select
                      placeholder={getTranslation(
                        "actions.ExternalActionForm.field_type_placeholder"
                      )}
                    >
                      <Option value="string">String</Option>
                      <Option value="number">Number</Option>
                      <Option value="boolean">Boolean</Option>
                      <Option value="object">Object</Option>
                      <Option value="array">Array</Option>
                    </Select>
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {getTranslation(
                    "actions.ExternalActionForm.add_field_button"
                  )}
                </Button>
              </Form.Item>

              {isHubspotIntegrated ? (
                <Form.Item>
                  <Button
                    onClick={() => {
                      setIsModalVisible(true); // Open modal immediately
                      fetchHubspotContacts(organization?.id); // Fetch contacts
                    }}
                  >
                    {getTranslation(
                      "actions.ExternalActionForm.create_actions_using_hubspot_button"
                    )}
                  </Button>
                </Form.Item>
              ) : null}
            </>
          )}
        </Form.List>
      </Form>

      <Modal
        title="Select Hubspot Fields"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleApply}>
            Apply
          </Button>,
        ]}
      >
        {loading ? (
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Select
            mode="multiple"
            allowClear
            placeholder="Select fields"
            value={selectedFields}
            onChange={setSelectedFields}
            showSearch
            style={{ width: "100%", overflow: "auto" }}
            dropdownRender={(menu) => (
              <>
                <div
                  style={{
                    overflowY: "scroll",
                    padding: "8px",
                  }}
                >
                  {menu}
                </div>
                <div style={{ textAlign: "center", padding: "8px" }}>
                  <Button type="primary" onClick={handleApply}>
                    Apply
                  </Button>
                </div>
              </>
            )}
          >
            {hubspotFields.map((field) => (
              <Option key={field.name} value={field.name}>
                <Checkbox checked={selectedFields.includes(field.name)}>
                  {field.name}
                </Checkbox>
              </Option>
            ))}
          </Select>
        )}
      </Modal>

      {sheets?.length > 0 && (
        <Select
          placeholder="Select Sheet"
          onChange={(value) => setSelectedSheetID(value)}
          className="w-full"
          allowClear
          defaultValue={selectedId}
          value={selectedId}
        >
          {sheets?.map((sheet) => {
            return <Option value={sheet.id}>{sheet?.name}</Option>;
          })}
        </Select>
      )}

      {/* {actions.length > 0 && (
        <>
          <Divider />

          <div className="flex items-center justify-center">
            <Button
              type="link"
              size="small"
              onClick={() => setShow(!show)}
              className="text-xs"
            >
              View Created Actions
            </Button>
          </div>
        </>
      )} */}
    </>
  );
};

export default ExternalActionForm;
