import React, { useState, useEffect } from "react";
import { Table, Button, message, Space, Modal } from "antd";
import { fetchPhoneNumbers } from "../../api/phoneNumbers";
import { useAuth } from "../../AuthContext";
import ImportPhoneNumberModal from "./ImportPhoneNumberModal";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

const PhoneNumberListPage = () => {
  const { getTranslation } = useLocalization(); // Use the localization hook
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userData } = useAuth();

  useEffect(() => {
    fetchNumbersLocal();
  }, []);

  const fetchNumbersLocal = async () => {
    try {
      const numbersData = await fetchPhoneNumbers(userData.orgId);
      setNumbers(numbersData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch phone numbers:", error);
      message.error(
        getTranslation("phoneNumber.PhoneNumberListPage.fetchError")
      );
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleImportSuccess = () => {
    fetchNumbersLocal();
  };

  const columns = [
    {
      title: getTranslation("phoneNumber.PhoneNumberListPage.numberColumn"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: getTranslation("phoneNumber.PhoneNumberListPage.providerColumn"),
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: getTranslation("phoneNumber.PhoneNumberListPage.nameColumn"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: getTranslation("phoneNumber.PhoneNumberListPage.createdAtColumn"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text._seconds * 1000).toLocaleString(),
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">
            {getTranslation("phoneNumber.PhoneNumberListPage.title")}
          </h1>
          <Space>
            <ImportPhoneNumberModal fetchNumbers={handleImportSuccess} />
          </Space>
        </div>
        <Table
          columns={columns}
          dataSource={numbers}
          loading={loading}
          pagination={{ pageSize: 30 }}
          rowKey="id"
          className="bg-white p-4 rounded-lg border"
        />
      </div>
    </div>
  );
};

export default PhoneNumberListPage;
