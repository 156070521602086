import { Checkbox, Spin } from "antd";
import { useState } from "react";
import { useLocalization } from "../context/LocalizationContext"; // Localization import

const GenericTable = ({ data, columns, loading }) => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - maxPagesBeforeCurrentPage &&
          i <= currentPage + maxPagesAfterCurrentPage)
      ) {
        pageNumbers.push(
          <button
            key={i}
            className={`px-3 py-1 ml-1 rounded-md ${
              currentPage === i ? "bg-gray-200 text-black" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      } else if (
        i === 2 ||
        i === totalPages - 1 ||
        (i === currentPage - maxPagesBeforeCurrentPage - 1 &&
          currentPage > maxPagesToShow) ||
        (i === currentPage + maxPagesAfterCurrentPage + 1 &&
          currentPage < totalPages - maxPagesToShow + 1)
      ) {
        pageNumbers.push(
          <span key={i} className="px-3 py-1 ml-1 rounded-md">
            ...
          </span>
        );
      }
    }

    return (
      <div className="flex justify-between items-center mt-8">
        <button
          className={`px-3 py-1 ml-1 rounded-md border border-gray-200 ${
            currentData === 1 ? "bg-gray-200" : "bg-white"
          }`}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {getTranslation("table.GenericTable.previous_button")}
        </button>
        <div className="flex items-center">{pageNumbers}</div>
        <button
          className="px-3 py-1 ml-1 rounded-md border border-gray-300 bg-white"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {getTranslation("table.GenericTable.next_button")}
        </button>
      </div>
    );
  };

  return (
    <div className="bg-white w-full h-full p-4">
      <div className="container mx-auto h-full bg-white">
        {loading ? (
          <div className="flex items-center justify-center h-screen">
            <Spin />
          </div>
        ) : (
          <>
            <table className="table-auto w-full ">
              <thead>
                <tr className="bg-gray-200">
                  {columns.map((col, index) => (
                    <th key={index} className="px-4 py-4 text-xs text-left">
                      {col.checkbox && <Checkbox />}
                      {col.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-200 border-b-[1px]">
                    {columns.map((col, colIndex) => (
                      <td key={colIndex} className="px-4 py-4 text-xs">
                        {col.render
                          ? col.render(item[col.key], item)
                          : item[col.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {data && data?.length === 0 && (
              <div className="flex items-center justify-center h-40">
                {getTranslation("table.GenericTable.no_data")}
              </div>
            )}

            {data?.length > 0 && renderPagination()}
          </>
        )}
      </div>
    </div>
  );
};

export default GenericTable;
