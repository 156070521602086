// NotAuthorizedPage.js
import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const BlockPage = () => {
  const navigate = useNavigate();
  const { getTranslation } = useLocalization();
  const { logout } = useAuth();

  return (
    <div className="not-authorized-container">
      <div className="not-authorized-content">
        <div>
          <Button type="primary" size="large">
            {getTranslation("authentication.unauthorized.tempBlockMessage")}
          </Button>
          <Button
            size="large"
            danger
            type="primary"
            onClick={() => {
              logout();
            }}
            className="ml-10 "
          >
            {getTranslation("authentication.logout.logoutButton")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .not-authorized-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          z-index: 9999;
        }
        .not-authorized-content {
          text-align: center;
          background: white;
          padding: 20px;
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};

export default BlockPage;
