import { firestore } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

const permissionCollection = collection(firestore, "permissions");

export const createPermission = async (data) => {
  const docRef = doc(permissionCollection, data.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    throw new Error("Permission with this ID already exists");
  }
  await setDoc(docRef, data);
  return docRef.id;
};

export const updatePermission = async (id, data) => {
  const docRef = doc(firestore, "permissions", id);
  await updateDoc(docRef, data);
};

export const deletePermission = async (id) => {
  const docRef = doc(firestore, "permissions", id);
  await deleteDoc(docRef);
};

export const fetchPermissions = async () => {
  const querySnapshot = await getDocs(permissionCollection);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const createAllPermissions = async (permissions) => {
  const promises = permissions.map(async (permission) => {
    try {
      await createPermission(permission);
      console.log(`Created permission: ${permission.name}`);
    } catch (error) {
      console.error(
        `Error creating permission ${permission.name}:`,
        error.message
      );
    }
  });

  await Promise.all(promises);
};
