import React from "react";
import CallPage from "./CallPage";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

export default function OutGoingCalls() {
  const { getTranslation } = useLocalization();
  return (
    <div>
      <CallPage
        title={getTranslation("call.OutgoingCalls")}
        type="outboundPhoneCall"
      />
    </div>
  );
}
