import React, { useState } from "react";
import { Input, Button, notification } from "antd";
import {
  addHeadersToGoogleSheet,
  addRowToGoogleSheet,
  updateGoogleSheetByPhone,
} from "../../api/googleSheetsService";
import { useLocalization } from "../../context/LocalizationContext"; // Import translation utility

const UpdateGoogleSheet = ({ sheetId }) => {
  const [phone, setPhone] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newGender, setNewGender] = useState("");
  const { getTranslation } = useLocalization();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await addRowToGoogleSheet({
        sheetId,
        phone,
        newName,
        newEmail,
        newGender,
      });

      setLoading(false);

      notification.success({
        message: getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.success_message"
        ),
        description: response.message,
      });
    } catch (error) {
      setLoading(false);

      console.error("Error updating Google Sheet:", error);
      notification.error({
        message: getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.error_message"
        ),
        description: getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.error_description"
        ),
      });
    }
  };

  return (
    <div className="p-4 max-w-lg mx-auto">
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          const response = await addHeadersToGoogleSheet({ sheetId });

          try {
            notification.success({
              message: getTranslation(
                "settings.Integrations.google_sheet.UpdateGoogleSheet.success_message"
              ),
              description: response.message,
            });
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        }}
      >
        {getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.header_button"
        )}
      </Button>

      <h2 className="text-xl font-bold mb-4">
        {getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.title"
        )}
      </h2>

      <Input
        placeholder={getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.placeholder_phone"
        )}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className="mb-2"
      />
      <Input
        placeholder={getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.placeholder_name"
        )}
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        className="mb-2"
      />
      <Input
        placeholder={getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.placeholder_email"
        )}
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        className="mb-2"
      />
      <Input
        placeholder={getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.placeholder_gender"
        )}
        value={newGender}
        onChange={(e) => setNewGender(e.target.value)}
        className="mb-2"
      />
      <Button loading={loading} type="primary" onClick={handleUpdate}>
        {getTranslation(
          "settings.Integrations.google_sheet.UpdateGoogleSheet.update_button"
        )}
      </Button>
    </div>
  );
};

export default UpdateGoogleSheet;
