import { Typography } from "antd";
import React from "react";
import IconLogo from "../assets/logo/leadsmotion_icon.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useLocalization } from "../context/LocalizationContext";

export default function LoginHeader() {
  const location = useLocation();
  const { getTranslation } = useLocalization();

  let pathname = location.pathname;
  let isRegistration = pathname === "/signup";

  return (
    <div className="px-20 py-2 flex items-center justify-between w-full">
      <div>
        <img className="w-10 h-10" src={IconLogo} />
      </div>
      <div className="flex">
        <Typography.Text className="block text-sm text-[#525866]">
          {isRegistration
            ? getTranslation("authentication.signup.loginPrompt.text")
            : getTranslation("authentication.login.registerPrompt.text")}
          {` `}
        </Typography.Text>
        <Typography.Text className="block text-sm font-medium ml-1 underline">
          {isRegistration ? (
            <Link to="/login" className="text-black ">
              {getTranslation("authentication.signup.loginPrompt.link")}
            </Link>
          ) : (
            <Link to="/signup" className="text-black ">
              {getTranslation("authentication.login.registerPrompt.link")}
            </Link>
          )}
        </Typography.Text>
      </div>
    </div>
  );
}
