import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input } from "antd";
import Label from "../../components/Label";
import SeeAllActions from "../agents/SeeAllActions";
import { useLocalization } from "../../context/LocalizationContext";

const TransferCallActionForm = ({ onChange, actions }) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const { getTranslation } = useLocalization();

  const handleValuesChange = (_, allValues) => {
    if (onChange) {
      const actionData = {
        type: "transferCall",
        destinations: [
          {
            type: "phoneNumber",
            number: "+" + allValues.phoneNumber,
            message: allValues.description,
          },
        ],
        function: {
          name: allValues?.name,
          description:
            "Use this function to transfer the call. Only use it when following instructions that explicitly ask you to use the transferCall function. DO NOT call this function unless you are instructed to do so.",
          parameters: {
            type: "object",
            properties: {
              destination: {
                type: "string",
                enum: ["+" + allValues.phoneNumber],
                description: allValues.description,
              },
            },
            required: ["destination"],
          },
        },
      };

      onChange(actionData);
    }
  };

  const fillTheForm = async (value) => {
    form.setFieldsValue({
      phoneNumber:
        value?.destinations?.length > 0 &&
        value?.destinations[0].number?.replace("+", ""),
      name: value?.function.name,
      description:
        value?.destinations?.length > 0 && value?.destinations[0].message,
    });

    const actionData = {
      type: "transferCall",
      destinations: value?.destinations,
      function: value?.function,
    };

    onChange(actionData);
  };

  return (
    <>
      <SeeAllActions
        fillTheForm={fillTheForm}
        show={show}
        setShow={setShow}
        actions={actions}
      />

      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange} // Listen to any value changes in the form
      >
        <Form.Item
          name="phoneNumber"
          label={
            <Label>
              {getTranslation(
                "actions.TransferCallActionForm.phone_number_label"
              )}
            </Label>
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.TransferCallActionForm.enter_phone_number_message"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "actions.TransferCallActionForm.enter_phone_number_placeholder"
            )}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label={
            <Label>
              {getTranslation(
                "actions.TransferCallActionForm.action_name_label"
              )}
            </Label>
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.TransferCallActionForm.enter_action_name_message"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "actions.TransferCallActionForm.enter_action_name_placeholder"
            )}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={
            <Label>
              {getTranslation(
                "actions.TransferCallActionForm.description_label"
              )}
            </Label>
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.TransferCallActionForm.enter_description_message"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "actions.TransferCallActionForm.enter_description_placeholder"
            )}
          />
        </Form.Item>
      </Form>

      {/* {actions.length > 0 && (
        <>
          <Divider />

          <div className="flex items-center justify-center">
            <Button
              type="link"
              size="small"
              onClick={() => setShow(!show)}
              className="text-xs"
            >
              View All Phone Numbers
            </Button>
          </div>
        </>
      )} */}
    </>
  );
};

export default TransferCallActionForm;
