import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Card,
  Typography,
  Space,
  message,
  Drawer,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createAction, fetchActions } from "../../api/actionApi";
import { useAuth } from "../../AuthContext";
import { useLocalization } from "../../context/LocalizationContext";

const { Title } = Typography;
const { Option } = Select;

const CreateActionPage = ({ fetchUpdatedActions }) => {
  const { getTranslation } = useLocalization();
  const [form] = Form.useForm();
  const [actionType, setActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData, serviceConfig } = useAuth();
  const [actions, setActions] = useState([]);
  const [show, setShow] = useState(false);
  const service = serviceConfig?.service;

  const handleTypeChange = (value) => {
    setActionType(value);
  };

  const handleCreateAction = async (values) => {
    if (values.name?.includes(" ")) {
      message.error(
        getTranslation("actions.createActionPage.createActionNameError")
      );
      return;
    }
    try {
      setLoading(true);
      let actionData;
      if (values.type === "action_transfer_call") {
        if (service === "vapi") {
          // For "vapi" service

          // [
          //   {
          //     type: "endCall",
          //     function: {
          //       name: values.name,
          //       description: values.description,
          //       parameters: {
          //         type: "object",
          //         properties: {},
          //       },
          //       server: {
          //         url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/post-call-processing`,
          //       },
          //     },
          //   },
          // ]

          actionData = [
            {
              type: "transferCall",
              destinations: [
                {
                  type: "phoneNumber",
                  number: "+" + values.phoneNumber,
                  message: values.description,
                },
              ],
              function: {
                name: values?.name,
                description:
                  "Use this function to transfer the call. Only use it when following instructions that explicitly ask you to use the transferCall function. DO NOT call this function unless you are instructed to do so.",
                parameters: {
                  type: "object",
                  properties: {
                    destination: {
                      type: "string",
                      enum: ["+" + values.phoneNumber],
                      description: values.description,
                    },
                  },
                  required: ["destination"],
                },
              },
            },
          ];
        } else {
          actionData.config.phoneNumber = values.phoneNumber;
        }
      } else if (values.type === "action_external") {
        if (service === "vapi") {
          // For "vapi" service

          actionData = [
            {
              type: "function",
              function: {
                name: values.name,
                async: false,
                parameters: {
                  type: "object",
                  properties: values.inputSchema.reduce((acc, curr) => {
                    acc[curr.name] = { type: curr.type };
                    return acc;
                  }, {}),
                },
              },
              server: {
                url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/during-call-processing`,
              },
            },
          ];
        } else {
          // For other services (like vocode)
          actionData = {
            type: values.type,
            config: {
              speakOnSend: true,
              speakOnReceive: true,
            },
            actionTrigger: {
              type: "action_trigger_function_call",
              config: {},
            },
          };

          actionData.config.description = values.description;
          actionData.config.name = values.name;
          actionData.config.url =
            process.env.REACT_APP_SERVER_URL + "/leads/save-user-details";
          actionData.config.inputSchema = {
            type: "object",
            properties: values.inputSchema.reduce((acc, curr) => {
              acc[curr.name] = { type: curr.type };
              return acc;
            }, {}),
          };
        }
      }

      const res = await createAction({ ...actionData, orgId: userData?.orgId });
      setLoading(false);
      message.success(getTranslation("actions.createActionPage.success"));
      if (fetchUpdatedActions) {
        fetchUpdatedActions(res);
      }
    } catch (error) {
      console.error(
        getTranslation("actions.createActionPage.createError"),
        error
      );
      setLoading(false);
      message.error(getTranslation("actions.createActionPage.createError"));
    }
  };

  const getAllAction = async () => {
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
  };

  useEffect(() => {
    getAllAction();
  }, []);

  return (
    <div className="p-8">
      <div className="flex items-center justify-between">
        <Title level={2}>
          {getTranslation("actions.createActionPage.title")}
        </Title>
        {actions && actions?.length > 0 && (
          <Button onClick={() => setShow(!show)} className="" size="large">
            {show
              ? getTranslation("actions.createActionPage.hideActions")
              : getTranslation("actions.createActionPage.seeActions")}
          </Button>
        )}
      </div>

      <Card className="bg-white p-4 rounded-lg">
        <Form form={form} layout="vertical" onFinish={handleCreateAction}>
          <Form.Item
            name="type"
            label={getTranslation("actions.createActionPage.typeLabel")}
            rules={[
              {
                required: true,
                message: getTranslation("actions.createActionPage.typeError"),
              },
            ]}
          >
            <Select
              placeholder={getTranslation(
                "actions.createActionPage.typePlaceholder"
              )}
              onChange={handleTypeChange}
            >
              <Option value="action_transfer_call">
                {getTranslation("actions.createActionPage.callRedirection")}
              </Option>
              <Option value="action_external">
                {getTranslation("actions.createActionPage.collectUserData")}
              </Option>
            </Select>
          </Form.Item>

          {actionType === "action_transfer_call" && (
            <Form.Item
              name="phoneNumber"
              label={getTranslation(
                "actions.createActionPage.phoneNumberLabel"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "actions.createActionPage.phoneNumberError"
                  ),
                },
              ]}
            >
              <Input
                placeholder={getTranslation(
                  "actions.createActionPage.phoneNumberPlaceholder"
                )}
              />
            </Form.Item>
          )}

          <Form.Item
            name="name"
            label={getTranslation("actions.createActionPage.nameLabel")}
            rules={[
              {
                required: true,
                message: getTranslation("actions.createActionPage.nameError"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: getTranslation(
                  "actions.createActionPage.descriptionError"
                ),
              },
            ]}
            label={
              service === "vapi" && actionType === "action_transfer_call"
                ? getTranslation(
                    "actions.createActionPage.descriptionVapiLabel"
                  )
                : getTranslation("actions.createActionPage.descriptionLabel")
            }
          >
            <Input
              placeholder={
                service === "vapi" && actionType === "action_transfer_call"
                  ? getTranslation(
                      "actions.createActionPage.descriptionVapiPlaceholder"
                    )
                  : getTranslation(
                      "actions.createActionPage.descriptionPlaceholder"
                    )
              }
            />
          </Form.Item>

          {actionType === "action_external" && (
            <>
              <Form.List name="inputSchema">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: getTranslation(
                                "actions.createActionPage.fieldNameError"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={getTranslation(
                              "actions.createActionPage.fieldNamePlaceholder"
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "type"]}
                          fieldKey={[fieldKey, "type"]}
                          rules={[
                            {
                              required: true,
                              message: getTranslation(
                                "actions.createActionPage.fieldTypeError"
                              ),
                            },
                          ]}
                        >
                          <Select
                            placeholder={getTranslation(
                              "actions.createActionPage.fieldTypePlaceholder"
                            )}
                          >
                            <Option value="string">
                              {getTranslation(
                                "actions.createActionPage.fieldTypeString"
                              )}
                            </Option>
                            <Option value="number">
                              {getTranslation(
                                "actions.createActionPage.fieldTypeNumber"
                              )}
                            </Option>
                            <Option value="boolean">
                              {getTranslation(
                                "actions.createActionPage.fieldTypeBoolean"
                              )}
                            </Option>
                            <Option value="object">
                              {getTranslation(
                                "actions.createActionPage.fieldTypeObject"
                              )}
                            </Option>
                            <Option value="array">
                              {getTranslation(
                                "actions.createActionPage.fieldTypeArray"
                              )}
                            </Option>
                          </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {getTranslation(
                          "actions.createActionPage.addFieldButton"
                        )}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {getTranslation("actions.createActionPage.createActionButton")}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {actions && actions?.length > 0 && (
        <>
          <Drawer
            title={getTranslation("actions.createActionPage.drawerTitle")}
            visible={show}
            onClose={() => setShow(false)}
          >
            {actions?.map((action) => (
              <Card key={action?.id} className="mb-2">
                <Typography.Text strong>
                  {action?.type === "action_transfer_call"
                    ? `${action?.config?.phoneNumber}`
                    : action?.config?.name}
                </Typography.Text>
                {action?.type === "action_external" && (
                  <div>
                    <Typography.Title level={5}>
                      {getTranslation("actions.createActionPage.inputSchema")}
                    </Typography.Title>
                    {action?.config.inputSchema?.properties &&
                      Object.entries(action?.config.inputSchema.properties).map(
                        ([name, schema]) => (
                          <div key={name}>
                            <Typography.Text>
                              {name} ({schema.type})
                            </Typography.Text>
                          </div>
                        )
                      )}
                  </div>
                )}

                {action?.service === "vapi" && (
                  <div>
                    <Typography.Title level={5}>
                      {action?.function?.name}
                    </Typography.Title>

                    {action?.function.parameters?.properties &&
                      Object.entries(
                        action?.function.parameters?.properties
                      ).map(([name, schema]) => (
                        <div key={name}>
                          <Typography.Text>
                            {name} ({schema.type})
                          </Typography.Text>
                        </div>
                      ))}
                  </div>
                )}

                <Button
                  type="primary"
                  className="w-full mt-2"
                  onClick={() => {
                    fetchUpdatedActions && fetchUpdatedActions(action);
                  }}
                >
                  {getTranslation("actions.createActionPage.addAction")}
                </Button>
              </Card>
            ))}
          </Drawer>
        </>
      )}
    </div>
  );
};

export default CreateActionPage;
