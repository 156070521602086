import React, { useState, useEffect } from "react";
import { Form, Input, Select, Tabs } from "antd";
import countries from "../../data/countries.json"; // Adjust the path as necessary
import Label from "../../components/Label";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

const { TabPane } = Tabs;
const { Option } = Select;

const ImportPhoneNumberModal = ({
  handleImportPhoneNumber,
  selectedAgentData,
}) => {
  const { getTranslation } = useLocalization();
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("twilio");
  const [selectedCountry, setSelectedCountry] = useState(
    countries[0].dial_code
  );

  useEffect(() => {
    if (selectedAgentData) {
      if (selectedAgentData?.attachPhoneNumber?.provider === "twilio") {
        setActiveTab("twilio");
      } else if (selectedAgentData?.attachPhoneNumber?.provider === "vonage") {
        setActiveTab("vonage");
      }
      if (selectedAgentData?.attachPhoneNumber?.number) {
        const country = countries.find((c) =>
          selectedAgentData?.attachPhoneNumber?.number.startsWith(c.dial_code)
        );
        setSelectedCountry(
          country ? country.dial_code : countries[0].dial_code
        );
      }

      form.setFieldsValue({
        country: selectedCountry,
        phoneNumber: selectedAgentData?.attachPhoneNumber?.number,
        accountSID: selectedAgentData?.attachPhoneNumber?.twilioAccountSid,
        authToken: selectedAgentData?.attachPhoneNumber?.twilioAuthToken,
        label: selectedAgentData?.attachPhoneNumber?.name,
        apiKey: selectedAgentData?.attachPhoneNumber?.apiKey,
        apiSecret: selectedAgentData?.attachPhoneNumber?.apiSecret,
      });
    }
  }, [selectedAgentData, selectedCountry, form]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleValuesChange = (changedValues, allValues) => {
    const requestData = {
      provider: activeTab,
      number: "+" + allValues.phoneNumber,
      name: allValues.label,
    };

    if (activeTab === "twilio") {
      requestData.twilioAccountSid = allValues.accountSID;
      requestData.twilioAuthToken = allValues.authToken;
    } else if (activeTab === "vonage") {
      requestData.apiKey = allValues.apiKey;
      requestData.apiSecret = allValues.apiSecret;
    }

    if (handleImportPhoneNumber) {
      handleImportPhoneNumber(requestData);
    }
  };

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Twilio" key="twilio">
          <Form
            requiredMark={false}
            form={form}
            layout="vertical"
            onValuesChange={handleValuesChange}
          >
            <Form.Item
              name="country"
              label={
                <Label required>
                  {getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.countryLabel"
                  )}
                </Label>
              }
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.countryError"
                  ),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={getTranslation(
                  "phoneNumber.ImportPhoneNumberModalv2.countryPlaceholder"
                )}
                optionFilterProp="children"
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.dial_code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={
                <Label required>
                  {getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.phoneNumberLabelTwilio"
                  )}
                </Label>
              }
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.phoneNumberError"
                  ),
                },
              ]}
            >
              <Input
                placeholder="(555) 000-0000"
                addonBefore={selectedCountry}
              />
            </Form.Item>
            <Form.Item
              name="accountSID"
              label={
                <Label required>
                  {getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.accountSIDLabel"
                  )}
                </Label>
              }
              rules={[
                {
                  required: activeTab === "twilio",
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.accountSIDError"
                  ),
                },
              ]}
            >
              <Input placeholder="xxxxx" />
            </Form.Item>
            <Form.Item
              name="authToken"
              label={
                <Label required>
                  {getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.authTokenLabel"
                  )}
                </Label>
              }
              rules={[
                {
                  required: activeTab === "twilio",
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.authTokenError"
                  ),
                },
              ]}
            >
              <Input placeholder="xxxxx" />
            </Form.Item>
            <Form.Item
              name="label"
              label={
                <Label>
                  {getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.labelLabel"
                  )}
                </Label>
              }
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.labelError"
                  ),
                },
              ]}
            >
              <Input placeholder="xxxxxx" />
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Vonage" key="vonage">
          <Form
            form={form}
            layout="vertical"
            onValuesChange={handleValuesChange}
          >
            <Form.Item
              name="country"
              label={getTranslation(
                "phoneNumber.ImportPhoneNumberModalv2.countryLabel"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.countryError"
                  ),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={getTranslation(
                  "phoneNumber.ImportPhoneNumberModalv2.countryPlaceholder"
                )}
                optionFilterProp="children"
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.dial_code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={getTranslation(
                "phoneNumber.ImportPhoneNumberModalv2.phoneNumberLabelVonage"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.phoneNumberError"
                  ),
                },
              ]}
            >
              <Input addonBefore={selectedCountry} />
            </Form.Item>
            <Form.Item
              name="apiKey"
              label={getTranslation(
                "phoneNumber.ImportPhoneNumberModalv2.apiKeyLabel"
              )}
              rules={[
                {
                  required: activeTab === "vonage",
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.apiKeyError"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="apiSecret"
              label={getTranslation(
                "phoneNumber.ImportPhoneNumberModalv2.apiSecretLabel"
              )}
              rules={[
                {
                  required: activeTab === "vonage",
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.apiSecretError"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="label"
              label={getTranslation(
                "phoneNumber.ImportPhoneNumberModalv2.labelLabel"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "phoneNumber.ImportPhoneNumberModalv2.labelError"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ImportPhoneNumberModal;
