import React, { useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, Drawer, Spin, Typography } from "antd";
import Plus from "../../assets/plus.png";
import chevronUp from "../../assets/chevron-up.png";
import ImportPhoneNumberModal from "../phoneNumber/ImportPhoneNumberModalNew";
import CreateActionPage from "../actions/EditActionNew";
import TransferCallActionForm from "../actions/TransferCallActionForm";
import ExternalActionForm from "../actions/ExternalActionForm";
import { useAuth } from "../../AuthContext";
import { fetchActions } from "../../api/actionApi";
import { useLocalization } from "../../context/LocalizationContext";
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const CollapsibleUI = ({
  handleImportPhoneNumber,
  handleActionDataChangeFroCallRedirection,
  handleActionDataChangeCollectUserData,
  selectedAgentData,
  setSelectedGoogleSheetID
}) => {
  const { getTranslation } = useLocalization();
  const { userData } = useAuth();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllAction = async () => {
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
    setLoading(false);
  };
  useEffect(() => {
    getAllAction();
  }, []);

  const onChange = (key) => {
    // console.log(key);
  };

  const items = [
    {
      key: "1",
      label: getTranslation("agents.CollapsibleUI.importPhoneNumber"),
      children: (
        <ImportPhoneNumberModal
          selectedAgentData={selectedAgentData}
          handleImportPhoneNumber={handleImportPhoneNumber}
        />
      ),
    },
    {
      key: "2",
      label: getTranslation("agents.CollapsibleUI.callRedirection"),
      children: (
        <div>
          <TransferCallActionForm
            actions={actions?.filter(
              (act) =>
                act.type === "transferCall" &&
                selectedAgentData?.tools?.includes(act.id)
            )}
            onChange={handleActionDataChangeFroCallRedirection}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: getTranslation("agents.CollapsibleUI.collectUserData"),
      children: (
        <div>
          <ExternalActionForm
            actions={actions?.filter(
              (act) =>
                act.type === "function" &&
                selectedAgentData?.tools?.includes(act.id)
            )}
            onChange={handleActionDataChangeCollectUserData}
            setSelectedGoogleSheetID={setSelectedGoogleSheetID}
            selectedAgentData={selectedAgentData}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <Card>
          <div className="flex items-center justify-center w-full h-[80]">
            <Spin />
          </div>
        </Card>
      ) : (
        <Collapse
          defaultActiveKey={["1"]}
          onChange={onChange}
          expandIconPosition={"end"}
          items={items}
          expandIcon={({ isActive }) =>
            isActive ? (
              <img src={chevronUp} className="w-5 h-5 object-contain" />
            ) : (
              <img src={Plus} className="w-3 h-3 object-contain" />
            )
          }
        />
      )}
    </>
  );
};
export default CollapsibleUI;
