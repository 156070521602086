import React from "react";
import { Button, notification } from "antd";
import { initiateGoogleAuth } from "../../api/googleSheetsService";
import GoogleSheetsList from "./GoogleSheetsList";
import { useLocalization } from "../../context/LocalizationContext";

const GoogleSheetsIntegration = () => {
  const { getTranslation } = useLocalization;
  const handleAuth = async () => {
    try {
      const response = await initiateGoogleAuth();
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error("Error during Google authentication:", error);
      notification.error({
        message: getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsIntegration.error_message"
        ),
        description: getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsIntegration.error_description"
        ),
      });
    }
  };

  return (
    <div className="p-4 max-w-lg mx-auto">
      <h2 className="text-xl font-bold mb-4">
        {getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsIntegration.title"
        )}
      </h2>
      <Button type="primary" onClick={handleAuth}>
        {getTranslation(
          "settings.Integrations.google_sheet.GoogleSheetsIntegration.connect_button"
        )}
      </Button>

      <GoogleSheetsList />
    </div>
  );
};

export default GoogleSheetsIntegration;
