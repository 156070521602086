import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import GlobalModal from "../../components/GlobalModal";
import { useLocalization } from "../../context/LocalizationContext";
const { Option } = Select;
const CreateLeadForm = ({ visible, loading, onCreate, onCancel, editLead }) => {
  const { getTranslation } = useLocalization();
  const [form] = Form.useForm();

  useEffect(() => {
    if (editLead) {
      form.setFieldsValue(editLead);
    } else {
      form.resetFields();
    }
  }, [editLead, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate({ ...values, status: "not_called_yet" });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <GlobalModal
      loading={loading}
      visible={visible}
      title={
        editLead
          ? getTranslation("organization.CreateLeadForm.edit_contact_title")
          : getTranslation(
              "organization.CreateLeadForm.create_new_contact_title"
            )
      }
      onCancel={onCancel}
      onOk={handleSubmit}
      requiredPermission={
        editLead
          ? getTranslation("organization.CreateLeadForm.permission_edit_leads")
          : getTranslation(
              "organization.CreateLeadForm.permission_create_leads"
            )
      }
      // footer={[
      //   <Button key="back" onClick={onCancel}>
      //     Cancel
      //   </Button>,
      //   <Button
      //     key="submit"
      //     type="primary"
      //     loading={loading}
      //     onClick={handleSubmit}
      //   >
      //     {editLead ? "Update" : "Create"}
      //   </Button>,
      // ]}
    >
      <Form form={form} layout="vertical" name="create_lead_form">
        <Form.Item
          name="name"
          label={getTranslation("organization.CreateLeadForm.name_label")}
          rules={[
            {
              required: true,
              message: getTranslation(
                "organization.CreateLeadForm.name_required_message"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "organization.CreateLeadForm.name_placeholder"
            )}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={getTranslation("organization.CreateLeadForm.phone_label")}
          rules={[
            {
              required: true,
              message: getTranslation(
                "organization.CreateLeadForm.phone_required_message"
              ),
            },
          ]}
        >
          <Input
            placeholder={getTranslation(
              "organization.CreateLeadForm.phone_placeholder"
            )}
          />
        </Form.Item>
      </Form>
    </GlobalModal>
  );
};

export default CreateLeadForm;
