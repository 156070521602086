import React from "react";
import { Modal, Typography } from "antd";
import { useAuth } from "../AuthContext";
import { useLocalization } from "../context/LocalizationContext"; // Localization import
import "./GlobalModal.css";

const GlobalModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  requiredPermission,
  children,
  title,
  noFooter,
  bodyStyle,
}) => {
  const { plan, userData } = useAuth();
  const { getTranslation } = useLocalization(); // Use localization hook

  // Function to check if the required permission exists in the permissions array
  const hasPermission = () => {
    return plan?.permissions?.some(
      (permission) => permission.name === requiredPermission
    );
  };

  const isAuthorized = userData.roleId === "owner" || hasPermission();

  return (
    <>
      {visible && <div className="blurred-background" />}
      <Modal
        bodyStyle={bodyStyle}
        footer={noFooter}
        title={
          isAuthorized
            ? title
            : getTranslation("organization.GlobalModal.title_not_authorized")
        }
        visible={visible}
        onOk={isAuthorized ? onOk : onCancel}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        {isAuthorized ? (
          children
        ) : (
          <div className="blurred-content">
            <Typography.Text>
              {getTranslation(
                "organization.GlobalModal.not_authorized_message"
              )}
            </Typography.Text>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GlobalModal;
