import { Button, Card, Divider, Drawer, Typography } from "antd";
import React, { useEffect } from "react";
import { useLocalization } from "../../context/LocalizationContext";

export default function SeeAllActions({ actions, show, setShow, fillTheForm }) {
  const { getTranslation } = useLocalization();
  console.log(actions, "actions");

  useEffect(() => {
    if (actions.length > 0) {
      fillTheForm(actions[0]);
    }
  }, []);

  return (
    <div>
      <Drawer
        title={getTranslation("agents.SeeAllActions.title")}
        visible={show}
        onClose={() => setShow(false)}
      >
        {actions?.map((action) => {
          return (
            <Card key={action?.id} className="mb-2">
              <Typography.Text strong>
                {action?.type === "action_transfer_call"
                  ? `${action?.config?.phoneNumber}`
                  : action?.config?.name}
              </Typography.Text>

              {action?.type === "action_external" && (
                <div>
                  <Typography.Title level={5}>
                    {getTranslation("agents.SeeAllActions.input_schema")}
                  </Typography.Title>
                  {action?.config.inputSchema?.properties &&
                    Object.entries(action?.config.inputSchema.properties).map(
                      ([name, schema]) => (
                        <div key={name}>
                          <Typography.Text>
                            {name} ({schema.type})
                          </Typography.Text>
                        </div>
                      )
                    )}
                </div>
              )}

              {action?.service === "vapi" && (
                <div>
                  <div className="flex items-center justify-between">
                    <Typography.Title level={5}>
                      {action?.function?.name}
                    </Typography.Title>

                    <Typography.Text>
                      {action?.destinations?.map((it) => it?.number)}
                    </Typography.Text>
                  </div>
                  <Divider />
                  {action?.function.parameters?.properties &&
                    Object.entries(action?.function.parameters?.properties).map(
                      ([name, schema]) => (
                        <div key={name}>
                          <Typography.Text>
                            {name} ({schema.type})
                          </Typography.Text>
                        </div>
                      )
                    )}
                </div>
              )}

              <Button
                className="w-full mt-2"
                onClick={() => {
                  setShow(false);
                  fillTheForm(action);
                }}
              >
                {getTranslation("agents.SeeAllActions.use_button")}
              </Button>
            </Card>
          );
        })}
      </Drawer>
    </div>
  );
}
