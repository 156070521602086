import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Divider,
  Typography,
  Modal,
} from "antd";
import { auth } from "../firebaseConfig";
import {
  updatePassword,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../context/LocalizationContext";
const { Title, Paragraph } = Typography;

const Settings = () => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const [apiKey, setApiKey] = useState("");
  const [form] = Form.useForm();
  const [reauthForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reauthVisible, setReauthVisible] = useState(false);
  const [passwordValues, setPasswordValues] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const storedApiKey = localStorage.getItem("voCodeToken");
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem("voCodeToken", apiKey);
    message.success(
      getTranslation("settings.SettingsComponent.api_key_save_success")
    );
  };

  const handlePasswordChange = async (values) => {
    setPasswordValues(values);
    setReauthVisible(true);
  };

  const handleReauthSubmit = async (values) => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        values.password
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwordValues.password);
      message.success(
        getTranslation("settings.SettingsComponent.password_change_success")
      );
      form.resetFields();
      setReauthVisible(false);
    } catch (error) {
      console.error("Error re-authenticating:", error);
      message.error(
        getTranslation("settings.SettingsComponent.reauthenticate_error")
      );
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center space-y-8">
      {/* API Key Section */}
      {/* <Card title={getTranslation('settings.SettingsComponent.api_key_title')} className="w-full max-w-md" bordered={false}>
        <Title level={4} className="text-center text-blue-500 mb-4">{getTranslation('settings.SettingsComponent.api_key_title')}</Title>
        <Form layout="vertical" onFinish={handleSave}>
          <Form.Item label={getTranslation('settings.SettingsComponent.api_key_label')} required>
            <Input
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder={getTranslation('settings.SettingsComponent.api_key_placeholder')}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }}>
              {getTranslation('settings.SettingsComponent.api_key_save_button')}
            </Button>
          </Form.Item>
        </Form>
      </Card> */}

      {/* Change Password Section */}
      <Card
        title={getTranslation(
          "settings.SettingsComponent.change_password_title"
        )}
        className="w-full max-w-md"
        bordered={false}
      >
        <Title level={4} className="text-center text-blue-500 mb-4">
          {getTranslation("settings.SettingsComponent.change_password_title")}
        </Title>
        <Form layout="vertical" form={form} onFinish={handlePasswordChange}>
          <Form.Item
            name="password"
            label={getTranslation(
              "settings.SettingsComponent.new_password_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "settings.SettingsComponent.new_password_placeholder"
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={getTranslation(
              "settings.SettingsComponent.confirm_password_label"
            )}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "settings.SettingsComponent.confirm_password_placeholder"
                ),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      getTranslation(
                        "settings.SettingsComponent.password_mismatch_error"
                      )
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
            >
              {getTranslation(
                "settings.SettingsComponent.change_password_button"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {/* Logout Button */}
      <Card className="w-full max-w-md" bordered={false}>
        <Button
          type="default"
          onClick={handleLogout}
          block
          style={{
            backgroundColor: "#FF4D4F",
            borderColor: "#FF4D4F",
            color: "#fff",
          }}
        >
          {getTranslation("settings.SettingsComponent.logout_button")}
        </Button>
      </Card>

      {/* Re-authentication Modal */}
      <Modal
        visible={reauthVisible}
        title={getTranslation(
          "settings.SettingsComponent.reauthenticate_title"
        )}
        onCancel={() => setReauthVisible(false)}
        footer={null}
      >
        <Form form={reauthForm} onFinish={handleReauthSubmit}>
          <Form.Item
            name="password"
            label={getTranslation(
              "settings.SettingsComponent.reauthenticate_password_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "settings.SettingsComponent.reauthenticate_password_placeholder"
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {getTranslation(
                "settings.SettingsComponent.reauthenticate_button"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Settings;
