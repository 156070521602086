import React, { useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Button, Divider, Progress, Typography } from "antd";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import { useAuth } from "../AuthContext";
import { useLocalization } from "../context/LocalizationContext"; // Localization import

function CSVUploadComponent() {
  const { getTranslation } = useLocalization(); // Use localization hook
  const [showProgress, setShowProgress] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const { userData } = useAuth();

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    toast.info(
      `${acceptedFiles[0].name} ${getTranslation(
        "csvUpload.CSVUploadComponent.file_selected_info"
      )}`
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

  const handleFileUpload = async () => {
    if (!file) {
      toast.error(
        getTranslation("csvUpload.CSVUploadComponent.select_file_error")
      );
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", file);

    const response = await fetch(
      `${process.env.REACT_APP_MEDIA_SERVER_URL}/upload-csv/${userData?.orgId}`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      toast.error(getTranslation("csvUpload.CSVUploadComponent.upload_error"));
      return;
    }

    const reader = response.body.getReader();
    setShowProgress(true);

    const stream = new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            controller.close();
            break;
          }
          const text = new TextDecoder().decode(value);
          text.split("\n").forEach((line) => {
            if (line.startsWith("data:")) {
              try {
                const data = JSON.parse(line.replace("data: ", ""));
                if (data.progress !== undefined) {
                  const progressValue = parseFloat(data.progress);
                  setProgress(progressValue.toFixed(2));
                  if (progressValue >= 100) {
                    setShowProgress(false);
                    toast.success(
                      getTranslation(
                        "csvUpload.CSVUploadComponent.processing_complete_success"
                      )
                    );
                  }
                }
              } catch (error) {
                console.error("Error parsing SSE data:", error);
              }
            }
          });
        }
      },
    });

    new Response(stream).text();
  };

  return (
    <div>
      <ToastContainer />
      <h2 className="text-2xl font-semibold text-center text-purple-600 mb-6">
        {getTranslation("csvUpload.CSVUploadComponent.title")}
      </h2>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          border: "2px dashed #7D4CDB",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        <p>{getTranslation("csvUpload.CSVUploadComponent.dropzone_prompt")}</p>
      </div>
      <Button
        onClick={handleFileUpload}
        type="primary"
        block
        style={{
          marginTop: "20px",
          backgroundColor: "#7D4CDB",
          borderColor: "#7D4CDB",
        }}
      >
        {getTranslation("csvUpload.CSVUploadComponent.upload_button")}
      </Button>
      <div style={{ marginTop: "20px" }}>
        {showProgress && (
          <>
            <Divider />
            <Progress percent={progress} status="active" size={["100%", 20]} />
            <Divider />
            <Typography.Text type="secondary">
              {getTranslation("csvUpload.CSVUploadComponent.progress_message")}
            </Typography.Text>
          </>

          // <CircularProgressbar
          //   value={progress}
          //   text={`${progress}%`}
          //   styles={buildStyles({
          //     rotation: 0.25,
          //     strokeLinecap: "butt",
          //     textSize: "16px",
          //     pathTransitionDuration: 0.5,
          //     pathColor: `rgba(62, 152, 199, ${progress / 100})`,
          //     textColor: "#f88",
          //     trailColor: "#d6d6d6",
          //     backgroundColor: "#3e98c7",
          //   })}
          // />
        )}
      </div>
    </div>
  );
}

export default CSVUploadComponent;
