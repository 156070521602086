import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  Modal,
  message,
  Breadcrumb,
  Divider,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createPermission,
  updatePermission,
  deletePermission,
  fetchPermissions,
} from "../../api/firebase/permissionApi";
import plus_white from "../../assets/plus_white.png";
import GenericTable from "../../components/GenericTable";
import { useLocalization } from "../../context/LocalizationContext"; // Localization import

const PermissionManagement = () => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState(false);
  const [editPermission, setEditPermission] = useState(null);
  const [permissionForm] = Form.useForm();

  useEffect(() => {
    loadPermissions();
  }, []);

  const loadPermissions = async () => {
    setLoading(true);
    try {
      const permissions = await fetchPermissions();
      setPermissions(permissions);
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation("users.PermissionManagement.fetch_permissions_error")
      );
      setLoading(false);
    }
  };

  const handlePermissionSubmit = async (values) => {
    setLoading(true);
    try {
      if (editPermission) {
        await updatePermission(editPermission.id, values);
        message.success(
          getTranslation("users.PermissionManagement.update_permission_success")
        );
      } else {
        const newPermission = { id: values.name, ...values };
        await createPermission(newPermission);
        message.success(
          getTranslation("users.PermissionManagement.create_permission_success")
        );
      }
      setIsPermissionModalVisible(false);
      loadPermissions();
    } catch (error) {
      message.error(
        getTranslation("users.PermissionManagement.save_permission_error")
      );
      setLoading(false);
    }
  };

  const handleDeletePermission = async (permissionId) => {
    setLoading(true);
    try {
      await deletePermission(permissionId);
      message.success(
        getTranslation("users.PermissionManagement.delete_permission_success")
      );
      loadPermissions();
    } catch (error) {
      message.error(
        getTranslation("users.PermissionManagement.delete_permission_error")
      );
      setLoading(false);
    }
  };

  const openPermissionModal = (permission) => {
    setEditPermission(permission);
    if (permission) {
      permissionForm.setFieldsValue(permission);
    } else {
      permissionForm.resetFields();
    }
    setIsPermissionModalVisible(true);
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="flex items-center justify-between w-full px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation(
                "users.PermissionManagement.breadcrumb_title"
              ),
            },
          ]}
        />

        <Button
          className="bg-black text-white flex items-center"
          onClick={() => openPermissionModal(null)}
        >
          <img src={plus_white} className="w-4 h-4 object-contain mr-1" />
          {getTranslation(
            "users.PermissionManagement.create_permission_button"
          )}
        </Button>
      </div>

      <Divider />

      <div className="w-full mb-8">
        <GenericTable
          data={permissions}
          columns={[
            {
              title: getTranslation("users.PermissionManagement.name_label"),
              dataIndex: "name",
              key: "name",
            },
            {
              title: getTranslation(
                "users.PermissionManagement.description_label"
              ),
              dataIndex: "description",
              key: "description",
            },
            {
              title: getTranslation("users.PermissionManagement.actions_label"),
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openPermissionModal(record)}
                  >
                    {getTranslation(
                      "users.PermissionManagement.edit_permission_button"
                    )}
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePermission(record.id)}
                    danger
                  >
                    {getTranslation(
                      "users.PermissionManagement.delete_permission_button"
                    )}
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </div>
      <Modal
        title={
          editPermission
            ? getTranslation("users.PermissionManagement.edit_permission_title")
            : getTranslation(
                "users.PermissionManagement.create_permission_title"
              )
        }
        visible={isPermissionModalVisible}
        onCancel={() => setIsPermissionModalVisible(false)}
        footer={null}
      >
        <Form
          form={permissionForm}
          layout="vertical"
          onFinish={handlePermissionSubmit}
        >
          <Form.Item
            name="name"
            label={getTranslation("users.PermissionManagement.name_label")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "users.PermissionManagement.name_placeholder"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={getTranslation(
              "users.PermissionManagement.description_label"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "users.PermissionManagement.description_placeholder"
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editPermission
                ? getTranslation(
                    "users.PermissionManagement.update_permission_button"
                  )
                : getTranslation(
                    "users.PermissionManagement.create_permission_button"
                  )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PermissionManagement;
