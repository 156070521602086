import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Space,
  Button,
  message,
  Switch,
  Typography,
  Breadcrumb,
  Divider,
  Input,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  fetchOrganizationsByOwner,
  updateOrganization,
} from "../../api/organizationApi";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../components/GenericTable";
import { useLocalization } from "../../context/LocalizationContext";

const { Title } = Typography;
const { Search } = Input;

const ListOfOrgs = () => {
  const { getTranslation } = useLocalization();
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    loadOrganizations();
  }, []);

  const loadOrganizations = async () => {
    setLoading(true);
    try {
      const orgs = await fetchOrganizationsByOwner(user.uid);
      setOrganizations(orgs);
      setFilteredOrganizations(orgs); // Initialize with all organizations
      setLoading(false);
    } catch (error) {
      message.error(
        getTranslation("organization.ListOfOrgs.fetch_failed_message")
      );
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();

    const filteredData = organizations.filter((org) =>
      org.name.toLowerCase().includes(searchValue)
    );
    setFilteredOrganizations(filteredData);
  };

  const handleUpdateOrganization = async (orgId, data) => {
    setLoading(true);
    try {
      await updateOrganization(orgId, data);
      message.success(
        getTranslation("organization.ListOfOrgs.update_success_message")
      );
      loadOrganizations();
    } catch (error) {
      message.error(
        getTranslation("organization.ListOfOrgs.update_failed_message")
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: getTranslation("organization.ListOfOrgs.name_column"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: getTranslation(
        "organization.ListOfOrgs.total_allocated_minutes_column"
      ),
      dataIndex: "totalAllocatedMinutes",
      key: "totalAllocatedMinutes",
    },
    {
      title: getTranslation("organization.ListOfOrgs.actions_column"),
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => navigate(`/organizations/${record.id}/members`)}
          >
            {getTranslation("organization.ListOfOrgs.members_button")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="bg-white w-full h-full">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation("organization.ListOfOrgs.title"),
            },
          ]}
        />
        <Divider />
      </div>
      <div className="px-4 pb-4">
        <Search
          placeholder={getTranslation(
            "organization.ListOfOrgs.search_organizations_placeholder"
          )}
          enterButton
          onSearch={handleSearch}
          onChange={(e) => {
            if (!e.target.value) {
              setFilteredOrganizations(organizations);
            }
          }}
        />
      </div>
      <div className="w-full mb-8">
        <GenericTable
          data={filteredOrganizations}
          columns={columns}
          loading={loading}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default ListOfOrgs;
