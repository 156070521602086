// src/pages/VerifyAccountPage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography, Form, Input, message, Spin } from "antd";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { useLocalization } from "../../context/LocalizationContext"; // Localization import

const { Title, Paragraph } = Typography;

const VerifyAccountPage = () => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const { user, userData, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user && userData && userData?.verified) {
      navigate("/");
    }
  }, [user, userData, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handlePasswordChange = async (values) => {
    setLoading(true);
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        values.currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      // Update the user's password
      await updatePassword(user, values.password);

      // Update the user's verification status in Firestore
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, { verified: true, isPasswordUpdated: true });

      message.success(
        getTranslation("users.VerifyAccountPage.success_message")
      );
      window.location.reload();
    } catch (error) {
      console.error("Error updating password or verifying account:", error);
      if (error.code === "auth/wrong-password") {
        message.error(
          getTranslation("users.VerifyAccountPage.wrong_password_error")
        );
      } else {
        message.error(getTranslation("users.VerifyAccountPage.generic_error"));
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <Card className="w-full max-w-3xl p-8">
        <Title level={2}>
          {getTranslation("users.VerifyAccountPage.title")}
        </Title>
        <Paragraph>
          {getTranslation("users.VerifyAccountPage.paragraph")}
        </Paragraph>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form form={form} layout="vertical" onFinish={handlePasswordChange}>
            <Form.Item
              name="currentPassword"
              label={getTranslation(
                "users.VerifyAccountPage.current_password_label"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "users.VerifyAccountPage.current_password_placeholder"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="password"
              label={getTranslation(
                "users.VerifyAccountPage.new_password_label"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "users.VerifyAccountPage.new_password_placeholder"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={getTranslation(
                "users.VerifyAccountPage.confirm_password_label"
              )}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "users.VerifyAccountPage.confirm_password_placeholder"
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        getTranslation(
                          "users.VerifyAccountPage.password_mismatch_error"
                        )
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {getTranslation(
                  "users.VerifyAccountPage.change_password_button"
                )}
              </Button>
            </Form.Item>
          </Form>
        )}
        <Button type="default" onClick={handleLogout}>
          {getTranslation("users.VerifyAccountPage.logout_button")}
        </Button>
      </Card>
    </div>
  );
};

export default VerifyAccountPage;
