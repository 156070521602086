// src/CreateUserForm.js
import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { useLocalization } from "../context/LocalizationContext"; // Localization import

const CreateUserForm = ({ visible, onCreate, onCancel, user }) => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
  }, [user, form]);

  return (
    <Modal
      visible={visible}
      title={
        user
          ? getTranslation("users.CreateUserForm.title_edit")
          : getTranslation("users.CreateUserForm.title_create")
      }
      okText={
        user
          ? getTranslation("users.CreateUserForm.ok_text_update")
          : getTranslation("users.CreateUserForm.ok_text_create")
      }
      cancelText={getTranslation("users.CreateUserForm.cancel_text")}
      onCancel={onCancel}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_user_form"
        onFinish={(values) => {
          onCreate(values);
          form.resetFields();
        }}
      >
        <Form.Item
          name="name"
          label={getTranslation("users.CreateUserForm.name_label")}
          rules={[
            {
              required: true,
              message: getTranslation("users.CreateUserForm.name_placeholder"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={getTranslation("users.CreateUserForm.email_label")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={getTranslation("users.CreateUserForm.phone_label")}
          rules={[
            {
              required: true,
              message: getTranslation("users.CreateUserForm.phone_placeholder"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserForm;
