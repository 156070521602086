// src/pages/OrganizationManagement.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Typography,
  Divider,
  Space,
  Table,
  Spin,
  Drawer,
  Breadcrumb,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createOrganization,
  updateOrganization,
  deleteOrganization,
  fetchOrganizationById,
} from "../../api/firebase/organizationApi";
import { fetchPlans } from "../../api/firebase/planApi";
import { fetchFeatures } from "../../api/firebase/featureApi";
import {
  createSubscription,
  fetchSubscriptionsByOrganizationId,
} from "../../api/firebase/subscriptionApi";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import GlobalModal from "../../components/GlobalModal";
import withAuthorization from "../../hoc/withAuthorization";
import { useLocalization } from "../../context/LocalizationContext";

const { Title, Text } = Typography;

const OrganizationManagement = () => {
  const { user, userData } = useAuth();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState(null);
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editOrganization, setEditOrganization] = useState(null);
  const [deleteOrganizationId, setDeleteOrganizationId] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const { getTranslation } = useLocalization();

  useEffect(() => {
    if (user) {
      loadOrganization();
      loadPlans();
    }
  }, [user]);

  const fetchFeatbyPlanId = async (planId) => {
    const res = await fetchFeatures(organization?.planId);
    setFeatures(res);
  };

  useEffect(() => {
    if (organization?.planId) {
      fetchFeatbyPlanId(organization.planId);
    }
  }, [organization]);

  const loadOrganization = async () => {
    setLoading(true);
    setFetchData(true);
    try {
      const org = await fetchOrganizationById(userData?.orgId);
      if (org) {
        setOrganization({
          ...org,
          leftoverMinutes:
            org?.totalAllocatedMinutes +
            org?.freePlanMinutes -
            org?.usedMinutes,
        });

        loadSubscriptions(org.id);
      }
      setLoading(false);
      setFetchData(false);
    } catch (error) {
      console.log(error);
      message.error(
        getTranslation("organization.OrganizationManagement.fetch_org_failure")
      );
      setLoading(false);
      setFetchData(false);
    }
  };

  const loadPlans = async () => {
    try {
      const fetchedPlans = await fetchPlans();
      setPlans(fetchedPlans);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.OrganizationManagement.fetch_plans_failure"
        )
      );
    }
  };

  const loadSubscriptions = async (organizationId) => {
    try {
      const fetchedSubscriptions = await fetchSubscriptionsByOrganizationId(
        organizationId
      );
      setSubscriptions(fetchedSubscriptions);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.OrganizationManagement.fetch_subscriptions_failure"
        )
      );
    }
  };

  const handleOrganizationSubmit = async (values) => {
    setLoading(true);
    try {
      if (editOrganization) {
        await updateOrganization(editOrganization.id, values);
        message.success(
          getTranslation(
            "organization.OrganizationManagement.org_update_success"
          )
        );
      } else {
        await createOrganization({
          name: values.name,
          ownerId: user.uid,
          createdAt: new Date(),
        });
        message.success(
          getTranslation(
            "organization.OrganizationManagement.org_create_success"
          )
        );
      }
      setIsModalVisible(false);
      loadOrganization();
    } catch (error) {
      message.error(
        getTranslation("organization.OrganizationManagement.org_save_failure")
      );
      setLoading(false);
    }
  };

  const handleDeleteOrganization = (id) => {
    setDeleteOrganizationId(id);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteOrganization(deleteOrganizationId);
      message.success(
        getTranslation("organization.OrganizationManagement.org_delete_success")
      );
      loadOrganization();
    } catch (error) {
      message.error(
        getTranslation("organization.OrganizationManagement.org_delete_failure")
      );
      setLoading(false);
    }
    setIsDeleteModalVisible(false);
  };

  const handlePlanPurchase = async (planId) => {
    setLoading(true);
    try {
      const detailPlan = plans?.find((pl) => pl.id === planId);

      const newSubscription = {
        userId: user.uid,
        planId,
        organizationId: organization.id,
        startDate: new Date(),
        allocatedMinutes: Number(detailPlan?.allocateMinutes), // Ensure this is a number
        usedMinutes: 0,
        paymentDetails: "Dummy payment details", // Dummy data for payment details
      };

      const subscription = await createSubscription(newSubscription);

      const totalAllocatedMinutes =
        (Number(organization?.totalAllocatedMinutes) || 0) +
        newSubscription.allocatedMinutes;
      const usedMinutes = Number(organization.usedMinutes) || 0;

      await updateOrganization(organization.id, {
        planId,
        subscriptionId: subscription.id,
        totalAllocatedMinutes,
        usedMinutes,
      });

      message.success(
        getTranslation(
          "organization.OrganizationManagement.plan_purchase_success"
        )
      );
      loadOrganization();
      setDrawerVisible(false);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.OrganizationManagement.plan_purchase_failure"
        )
      );
      setLoading(false);
    }
  };

  const detailPlan = plans?.find((pl) => pl.id === organization?.planId);

  console.log(organization);
  const subscriptionColumns = [
    {
      title: getTranslation(
        "organization.OrganizationManagement.subscription_plan_column"
      ),
      dataIndex: "name",
      key: "name",
      render: (row, record) => {
        const plan = plans?.find((pl) => pl.id === record?.planId);
        return <>{plan?.name}</>;
      },
    },
    {
      title: getTranslation(
        "organization.OrganizationManagement.subscription_allocated_minutes_column"
      ),
      dataIndex: "allocatedMinutes",
      key: "allocatedMinutes",
    },
    {
      title: getTranslation(
        "organization.OrganizationManagement.subscription_buy_date_column"
      ),
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => new Date(date.seconds * 1000).toLocaleDateString(),
    },
  ];

  if (fetchData)
    return (
      <div className="flex items-center justify-center w-full h-[80vh]">
        <Spin />
      </div>
    );

  return (
    <div className="min-h-screen  bg-white">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation(
                "organization.OrganizationManagement.breadcrumb_organization"
              ),
            },
            {
              title: organization?.name,
            },
          ]}
        />
      </div>
      <Divider />
      <div className="w-full max-w-6xl p-4">
        {organization ? (
          <>
            {organization.planId && (
              <>
                <Title level={4}>
                  {getTranslation(
                    "organization.OrganizationManagement.plan_details_title"
                  )}
                </Title>
                <Text>
                  <strong>
                    {getTranslation(
                      "organization.OrganizationManagement.plan_label"
                    )}
                    :
                  </strong>{" "}
                  {detailPlan?.name}
                </Text>
                <br />
                <Title level={5}>
                  {getTranslation(
                    "organization.OrganizationManagement.features_title"
                  )}
                </Title>
                {features?.map((feature, index) => (
                  <div key={index}>
                    <Text>&#8226; {feature?.name}</Text>
                    <br />
                    <Text>&#8226; {feature?.description}</Text>
                    <br />
                  </div>
                ))}
              </>
            )}
            <Divider />
            <Title level={4}>
              {getTranslation(
                "organization.OrganizationManagement.subscription_details_title"
              )}
            </Title>
            <Text>
              <strong>
                {getTranslation(
                  "organization.OrganizationManagement.total_allocated_minutes_label"
                )}
                :
              </strong>{" "}
              {organization.totalAllocatedMinutes}
            </Text>
            <br />
            <Text>
              <strong>
                {getTranslation(
                  "organization.OrganizationManagement.available_free_plan_minutes_label"
                )}
                :
              </strong>{" "}
              {organization?.freePlanMinutes?.toFixed(2)}
            </Text>
            <br />
            <Text>
              <strong>
                {getTranslation(
                  "organization.OrganizationManagement.used_minutes_label"
                )}
                :
              </strong>{" "}
              {organization.usedMinutes?.toFixed(2)}
            </Text>
            <br />
            <Text>
              <strong>
                {getTranslation(
                  "organization.OrganizationManagement.leftover_minutes_label"
                )}
                :
              </strong>{" "}
              {organization?.leftoverMinutes?.toFixed(2)}
            </Text>
            <Divider />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/organizations/${organization.id}/members`)
                }
              >
                {getTranslation(
                  "organization.OrganizationManagement.view_members_button"
                )}
              </Button>

              {organization?.planId ? (
                <Button type="default" onClick={() => navigate("/pricing")}>
                  {getTranslation(
                    "organization.OrganizationManagement.upgrade_plan_button"
                  )}
                </Button>
              ) : (
                <Button type="primary" onClick={() => navigate("/pricing")}>
                  {getTranslation(
                    "organization.OrganizationManagement.buy_plan_button"
                  )}
                </Button>
              )}

              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setEditOrganization(organization);
                  setIsModalVisible(true);
                }}
              >
                {getTranslation(
                  "organization.OrganizationManagement.edit_organization_button"
                )}
              </Button>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteOrganization(organization.id)}
                danger
              >
                {getTranslation(
                  "organization.OrganizationManagement.delete_organization_button"
                )}
              </Button>
            </Space>
            <Divider />
            <Title level={4}>
              {getTranslation(
                "organization.OrganizationManagement.all_subscriptions_title"
              )}
            </Title>
            <Table
              dataSource={subscriptions}
              columns={subscriptionColumns}
              rowKey="id"
              pagination={{ pageSize: 5 }}
              loading={loading}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh]">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
              {getTranslation(
                "organization.OrganizationManagement.create_organization_button"
              )}
            </Button>
          </div>
        )}
        <GlobalModal
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={() => {
            setIsModalVisible(false);
            setEditOrganization(null);
            form.resetFields();
          }}
          loading={loading}
          requiredPermission={
            editOrganization ? "canEditOrganizations" : "canCreateOrganizations"
          }
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOrganizationSubmit}
            initialValues={editOrganization}
          >
            <Form.Item
              name="name"
              label={getTranslation(
                "organization.OrganizationManagement.organization_name_label"
              )}
              rules={[
                {
                  required: true,
                  message: getTranslation(
                    "organization.OrganizationManagement.organization_name_required"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </GlobalModal>
        <GlobalDeleteModal
          visible={isDeleteModalVisible}
          onOk={handleDeleteConfirm}
          onCancel={() => setIsDeleteModalVisible(false)}
          loading={loading}
          requiredPermission="canDeleteOrganizations"
        />
        <Drawer
          title={getTranslation(
            "organization.OrganizationManagement.available_plans_drawer_title"
          )}
          placement="right"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          width={400}
        >
          <div className="flex flex-wrap">
            {plans.map((plan) => (
              <Card key={plan.id} className="m-2" title={plan.name} bordered>
                <p>
                  {getTranslation(
                    "organization.OrganizationManagement.minutes_label"
                  )}{" "}
                  - {plan.allocateMinutes}
                </p>
                <Button
                  type="primary"
                  onClick={() => handlePlanPurchase(plan.id)}
                >
                  {getTranslation(
                    "organization.OrganizationManagement.buy_now_button"
                  )}
                </Button>
              </Card>
            ))}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default OrganizationManagement;
