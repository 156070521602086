import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  Modal,
  message,
  Switch,
  Typography,
  Divider,
  Select,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createPlan,
  updatePlan,
  deletePlan,
  fetchPlans,
} from "../../api/firebase/planApi";
import {
  createFeature,
  updateFeature,
  deleteFeature,
  fetchFeatures,
} from "../../api/firebase/featureApi";
import { removeEmptyValues } from "../../utils/helper";
import { useAuth } from "../../AuthContext";
import { fetchOrganizationsByOwner } from "../../api/organizationApi";
import { fetchPermissions } from "../../api/firebase/permissionApi";
import { useLocalization } from "../../context/LocalizationContext";

const { Title, Text } = Typography;
const { Option } = Select;

const PlanManagement = () => {
  const { getTranslation } = useLocalization(); // Localization function
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
  const [isFeatureModalVisible, setIsFeatureModalVisible] = useState(false);
  const [editPlan, setEditPlan] = useState(null);
  const [editFeature, setEditFeature] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planForm] = Form.useForm();
  const [featureForm] = Form.useForm();
  const { user } = useAuth();
  const [allOrgs, setAllOrgs] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const getAllOrgs = async () => {
    const orgs = await fetchOrganizationsByOwner(user.uid);
    setAllOrgs(orgs);
  };

  const getAllPermissions = async () => {
    const perms = await fetchPermissions();
    setPermissions(perms);
  };

  useEffect(() => {
    if (isPlanModalVisible) {
      getAllOrgs();
      getAllPermissions();
    }
  }, [isPlanModalVisible]);

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const plans = await fetchPlans();
      const sortedPlans = plans.sort(
        (a, b) => (a.rank || Infinity) - (b.rank || Infinity)
      );
      setPlans(sortedPlans);
      setLoading(false);
    } catch (error) {
      message.error(getTranslation("plan.PlanManagement.deletePlanFailure"));
      setLoading(false);
    }
  };

  const loadFeatures = async (planId) => {
    setLoading(true);
    try {
      const features = await fetchFeatures(planId);
      setFeatures(features);
      setLoading(false);
    } catch (error) {
      message.error(getTranslation("plan.PlanManagement.deleteFeatureFailure"));
      setLoading(false);
    }
  };

  const handlePlanSubmit = async (values) => {
    setLoading(true);
    try {
      const cleanedValues = removeEmptyValues(values);
      cleanedValues.permissions = cleanedValues.permissions.map(
        (permissionId) =>
          permissions.find((permission) => permission.name === permissionId)
      );

      if (editPlan) {
        await updatePlan(editPlan.id, {
          ...cleanedValues,
          orgId: values?.orgId || "",
        });

        message.success(getTranslation("plan.PlanManagement.updatePlanButton"));
      } else {
        await createPlan({ ...cleanedValues, orgId: values?.orgId || "" });
        message.success(getTranslation("plan.PlanManagement.createPlanButton"));
      }
      setIsPlanModalVisible(false);
      loadPlans();
    } catch (error) {
      console.log(error);
      message.error(getTranslation("plan.PlanManagement.deletePlanFailure"));
      setLoading(false);
    }
  };

  const handleFeatureSubmit = async (values) => {
    setLoading(true);
    try {
      const cleanedValues = removeEmptyValues(values);
      if (editFeature) {
        await updateFeature(editFeature.id, cleanedValues);
        message.success(
          getTranslation("plan.PlanManagement.updateFeatureButton")
        );
      } else {
        await createFeature({ ...cleanedValues, planId: selectedPlan.id });
        message.success(
          getTranslation("plan.PlanManagement.createFeatureButton")
        );
      }
      setIsFeatureModalVisible(false);
      loadFeatures(selectedPlan.id);
    } catch (error) {
      message.error(getTranslation("plan.PlanManagement.deleteFeatureFailure"));
      setLoading(false);
    }
  };

  const handleDeletePlan = async (planId) => {
    setLoading(true);
    try {
      await deletePlan(planId);
      message.success(getTranslation("plan.PlanManagement.deletePlanSuccess"));
      loadPlans();
    } catch (error) {
      message.error(getTranslation("plan.PlanManagement.deletePlanFailure"));
      setLoading(false);
    }
  };

  const handleDeleteFeature = async (featureId) => {
    setLoading(true);
    try {
      await deleteFeature(featureId);
      message.success(
        getTranslation("plan.PlanManagement.deleteFeatureSuccess")
      );
      loadFeatures(selectedPlan.id);
    } catch (error) {
      message.error(getTranslation("plan.PlanManagement.deleteFeatureFailure"));
      setLoading(false);
    }
  };

  const openPlanModal = (plan) => {
    setEditPlan(plan);
    if (plan) {
      planForm.setFieldsValue({
        ...plan,
        permissions:
          plan.permissions?.map((permission) => permission.name) || [],
      });
    } else {
      planForm.resetFields();
    }
    setIsPlanModalVisible(true);
  };

  const openFeatureModal = (feature) => {
    setEditFeature(feature);
    if (feature) {
      featureForm.setFieldsValue(feature);
    } else {
      featureForm.resetFields();
    }
    setIsFeatureModalVisible(true);
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    loadFeatures(plan.id);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center">
      <Card className="w-full p-8 mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">
            {getTranslation("plan.PlanManagement.title")}
          </h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openPlanModal(null)}
          >
            {getTranslation("plan.PlanManagement.createPlanButton")}
          </Button>
        </div>
        <Table
          dataSource={plans}
          columns={[
            {
              title: getTranslation("plan.PlanManagement.planColumns.name"),
              dataIndex: "name",
              key: "name",
            },
            {
              title: getTranslation("plan.PlanManagement.planColumns.price"),
              dataIndex: "price",
              key: "price",
            },
            {
              title: getTranslation(
                "plan.PlanManagement.planColumns.description"
              ),
              dataIndex: "description",
              key: "description",
            },
            {
              title: getTranslation(
                "plan.PlanManagement.planColumns.allocatedMinutes"
              ),
              dataIndex: "allocateMinutes",
              key: "allocateMinutes",
            },
            {
              title: getTranslation("plan.PlanManagement.planColumns.rank"),
              dataIndex: "rank",
              key: "rank",
              render: (text) =>
                text !== undefined
                  ? text
                  : getTranslation(
                      "plan.PlanManagement.planColumns.rankNoRank"
                    ),
            },
            {
              title: getTranslation("plan.PlanManagement.planColumns.enabled"),
              dataIndex: "enabled",
              key: "enabled",
              render: (text, record) => (
                <Switch checked={record.enabled} disabled />
              ),
            },
            {
              title: getTranslation("plan.PlanManagement.planColumns.actions"),
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openPlanModal(record)}
                  >
                    {getTranslation(
                      "plan.PlanManagement.planColumns.editButton"
                    )}
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePlan(record.id)}
                  >
                    {getTranslation(
                      "plan.PlanManagement.planColumns.deleteButton"
                    )}
                  </Button>
                  <Button onClick={() => selectPlan(record)}>
                    {getTranslation(
                      "plan.PlanManagement.planColumns.viewFeaturesButton"
                    )}
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </Card>
      {selectedPlan && (
        <Modal
          title={getTranslation("plan.PlanManagement.featuresModalTitle", {
            planName: selectedPlan.name,
          })}
          onCancel={() => setSelectedPlan(null)}
          visible={selectedPlan !== null}
          footer={null}
        >
          <div className="w-full p-8">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-semibold">
                {getTranslation("plan.PlanManagement.featuresModalTitle", {
                  planName: selectedPlan.name,
                })}
              </h1>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => openFeatureModal(null)}
              >
                {getTranslation("plan.PlanManagement.addFeatureButton")}
              </Button>
            </div>
            {features.map((feature, index) => (
              <div key={index} className="mb-2">
                <Title level={4}>{feature.name}</Title>
                <Text>{feature.description}</Text>
                <Space style={{ float: "right" }}>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => openFeatureModal(feature)}
                  >
                    {getTranslation(
                      "plan.PlanManagement.planColumns.editButton"
                    )}
                  </Button>
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteFeature(feature.id)}
                  >
                    {getTranslation(
                      "plan.PlanManagement.planColumns.deleteButton"
                    )}
                  </Button>
                </Space>
                <Divider />
              </div>
            ))}
          </div>
        </Modal>
      )}
      <Modal
        title={
          editPlan
            ? getTranslation("plan.PlanManagement.editPlanModalTitle")
            : getTranslation("plan.PlanManagement.createPlanModalTitle")
        }
        visible={isPlanModalVisible}
        onCancel={() => setIsPlanModalVisible(false)}
        footer={null}
      >
        <Form form={planForm} layout="vertical" onFinish={handlePlanSubmit}>
          <Form.Item
            name="name"
            label={getTranslation("plan.PlanManagement.planNameLabel")}
            rules={[
              {
                required: true,
                message: getTranslation("plan.PlanManagement.planNameRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label={getTranslation("plan.PlanManagement.priceLabel")}
            rules={[
              {
                required: true,
                message: getTranslation("plan.PlanManagement.priceRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={getTranslation("plan.PlanManagement.descriptionLabel")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "plan.PlanManagement.descriptionRequired"
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="allocateMinutes"
            label={getTranslation("plan.PlanManagement.allocatedMinutesLabel")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "plan.PlanManagement.allocatedMinutesRequired"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="rank"
            label={getTranslation("plan.PlanManagement.rankLabel")}
          >
            <Select
              placeholder={getTranslation(
                "plan.PlanManagement.rankPlaceholder"
              )}
            >
              {[...Array(10).keys()].map((i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permissions"
            label={getTranslation("plan.PlanManagement.permissionsLabel")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "plan.PlanManagement.permissionsRequired"
                ),
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={getTranslation(
                "plan.PlanManagement.permissionsPlaceholder"
              )}
              optionLabelProp="label"
            >
              {permissions.map((permission) => (
                <Option
                  key={permission.name}
                  value={permission.name}
                  label={permission.name}
                >
                  {permission.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="enabled"
            label={getTranslation("plan.PlanManagement.enabledLabel")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="orgId"
            label={getTranslation("plan.PlanManagement.organizationLabel")}
          >
            <Select
              placeholder={getTranslation(
                "plan.PlanManagement.organizationPlaceholder"
              )}
            >
              {allOrgs.map((_org) => (
                <Option key={_org.id} value={_org.id}>
                  {_org.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editPlan
                ? getTranslation("plan.PlanManagement.updatePlanButton")
                : getTranslation("plan.PlanManagement.createPlanButton")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          editFeature
            ? getTranslation("plan.PlanManagement.editFeatureModalTitle")
            : getTranslation("plan.PlanManagement.createFeatureModalTitle")
        }
        visible={isFeatureModalVisible}
        onCancel={() => setIsFeatureModalVisible(false)}
        footer={null}
      >
        <Form
          form={featureForm}
          layout="vertical"
          onFinish={handleFeatureSubmit}
        >
          <Form.Item
            name="name"
            label={getTranslation("plan.PlanManagement.featureNameLabel")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "plan.PlanManagement.featureNameRequired"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={getTranslation(
              "plan.PlanManagement.featureDescriptionLabel"
            )}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "plan.PlanManagement.featureDescriptionRequired"
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="premium"
            label={getTranslation("plan.PlanManagement.premiumLabel")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="enabled"
            label={getTranslation("plan.PlanManagement.enabledLabel")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editFeature
                ? getTranslation("plan.PlanManagement.updateFeatureButton")
                : getTranslation("plan.PlanManagement.createFeatureButton")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PlanManagement;
