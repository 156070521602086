import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Select, Button, message, Spin, Typography } from "antd";

import languages from "../../languages";
import { fetchVoices, textToVoiceFetch } from "../../api/elevanLabsApi";
import { fetchPhoneNumbers, phoneNumbersCreate } from "../../api/phoneNumbers";
import {
  createAgent,
  updateAgent,
  fetchAgentById,
  fetchAgentByOrgId,
} from "../../api/agentApi";
import { getPrompt, getVoice } from "../../api/promptApi";
import { createAction, fetchActions, updateAction } from "../../api/actionApi";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import CreateActionPage from "../actions/EditAction";
import GlobalModal from "../../components/GlobalModal";
import ImportPhoneNumberModal from "../phoneNumber/ImportPhoneNumberModalNew";
import Label from "../../components/Label";
import CollapsibleUI from "./CollapsibleUI";
import { useLocalization } from "../../context/LocalizationContext"; // Import the localization hook
import { cartesiaVoices } from "../../api/cartesiaApi";
import { getPropertiesKeys } from "../../utils/helper";

const { Option } = Select;

const EditAgentDrawer = ({ fetchAgents, selectedAgentData }) => {
  const docId = selectedAgentData?.docId;
  const agentId = selectedAgentData?.agentResponse?.id;
  const [form] = Form.useForm();
  const { getTranslation } = useLocalization(); // Localization hook
  const [voices, setVoices] = useState([]);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selecteVoiceId, setSelecteVoiceId] = useState("");
  const [agents, setAgents] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [actions, setActions] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [actionType, setActionType] = useState("");
  const { userData } = useAuth();
  const [checked, setChecked] = useState(false);
  const [openCreateAction, setOpenCreateAction] = useState(false);
  const [numbers, setNumbers] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [phoneNumberFormData, setPhoneNumberFormData] = useState(null);
  const [selectedGoogleSheetID, setSelectedGoogleSheetID] = useState(null);
  const [selectedGoogleSheetHeaders, setGoogleSheetHeaders] = useState(null);
  const { serviceConfig } = useAuth();
  const service = serviceConfig?.service;

  const voicePreview = async () => {
    try {
      const response = await textToVoiceFetch(
        "21m00Tcm4TlvDq8ikWAM",
        "great job."
      );
      const audioBlob = new Blob([response], { type: "audio/mpeg" });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      if (audioRef.current) {
        audioRef.current.src = url;
        audioRef.current.play();
      }
    } catch (error) {
      console.error(
        getTranslation("agents.EditAgentDrawer.audioPreviewFailure"),
        error
      );
      message.error(
        getTranslation("agents.EditAgentDrawer.audioPreviewFailure")
      );
    }
  };

  const getAllNumbers = async () => {
    const numbersData = await fetchPhoneNumbers(userData.orgId);
    setNumbers(numbersData);
  };

  useEffect(() => {
    setLoading(true);
    getAllNumbers();

    const fetchData = async () => {
      try {
        // const voiceData = await fetchVoices();
        const voiceData = await cartesiaVoices();

        setVoices(voiceData);

        const actionsData = await fetchActions(1, 30, userData?.orgId);
        setActions(actionsData.items);

        if (agentId) {
          const agent = await fetchAgentById(agentId);
          setSelectedAgent(agent);

          const promptData = await getPrompt(agent.prompt.id);
          const voiceDetails = await getVoice(agent.voice.id);

          // const selectedVoice = voiceData.find(
          //   (voice) => voice.voice_id === voiceDetails.voiceId
          // );

          const selectedVoice = voiceData.find(
            (voice) => voice.id === voiceDetails.voiceId
          );

          form.setFieldsValue({
            agentName: agent.name,
            initialMessage: agent.initialMessage,
            voice: selectedVoice ? selectedVoice.name : "",
            language: agent.language,
            promptContent: promptData.content,
            orgName: agent.orgName,
            actions: agent.actions,
          });

          setSelecteVoiceId(voiceDetails.voiceId);
          setSelectedActions(agent?.actions || []);
          setLoading(false);
        } else {
          setLoading(false);
          setSelecteVoiceId(null);
        }
      } catch (error) {
        message.error(
          getTranslation("agents.EditAgentDrawer.fetchDataFailure")
        );
        console.error(
          getTranslation("agents.EditAgentDrawer.fetchDataFailure"),
          error
        );
        setLoading(false);
      }
    };

    const fetchDataFromVapi = async () => {
      try {
        const voiceData = await cartesiaVoices();
        const actionsData = await fetchActions(1, 30, userData?.orgId);
        setVoices(voiceData);
        setActions(actionsData.items);

        if (agentId) {
          const agent = await fetchAgentById(agentId);
          setSelectedAgent(agent);

          // const selectedVoice = voiceData.find(
          //   (voice) => voice.voice_id === agent.voice.voiceId
          // );

          const selectedVoice = voiceData.find(
            (voice) => voice.id === agent.voice.voiceId
          );

          form.setFieldsValue({
            agentName: agent.name,
            initialMessage: agent.firstMessage,
            voice: selectedVoice ? selectedVoice.name : "",
            language: agent?.transcriber?.language,
            promptContent: agent.model.messages[0]?.content,
            orgName: agent.orgName,
            actions: agent.actions,
          });

          setSelecteVoiceId(agent.voice.voiceId);

          let allItems = [];
          let array = agent?.model?.toolIds || [];
          for (let index = 0; index < array?.length; index++) {
            const element = array[index];
            let _item = actionsData?.items?.find((item) => item.id === element);
            allItems.push(_item);
          }

          setSelectedActions(allItems || []);
          setLoading(false);
        } else {
          form.resetFields();
          setLoading(false);
          setSelecteVoiceId(null);
        }
      } catch (error) {
        message.error(
          getTranslation("agents.EditAgentDrawer.fetchDataFailure")
        );
        console.error(
          getTranslation("agents.EditAgentDrawer.fetchDataFailure"),
          error
        );
        setLoading(false);
      }
    };

    if (service === "vapi") {
      fetchDataFromVapi();
    } else {
      fetchData();
    }
  }, [agentId, form, setSelecteVoiceId]);

  const handlePlayPause = (voice) => {
    if (!voice.preview_url) return;

    if (playingVoiceId === voice.voice_id) {
      audioRef.current.pause();
      setPlayingVoiceId(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(voice.preview_url);
      audioRef.current.play();
      setPlayingVoiceId(voice.voice_id);
      audioRef.current.onended = () => setPlayingVoiceId(null);
    }
  };

  const handleAgentCreateOrUpdate = (values) => {
    if (agentId) {
      handleEditAgent(values);
    } else {
      handleCreateAgent(values);
    }
  };

  const generatePrompt = (promptContent, actions) => {
    const externalActions = actions.filter(
      (action) => action.type === "action_external"
    );
    const prompts = externalActions.flatMap((action) => {
      const properties = action.config.inputSchema.properties;
      return Object.keys(properties).map((key) => key);
    });

    const uniquePrompts = [...new Set(prompts)]; // To remove duplicate prompts
    return `${promptContent} and please ask user about ${uniquePrompts.join(
      ", "
    )}.`;
  };

  const handleCreateAgent = async (values) => {
    let reqBody = {
      agentName: values.agentName,
      voiceElevanLabId: selecteVoiceId,
      promptContent: values?.promptContent,
      // generatePrompt(values?.promptContent, selectedActions),
      initialMessage: values.initialMessage,
      language: values.language,
      // actions: selectedActions?.map((action) => action.id),
      orgId: userData?.orgId,
      selectedGoogleSheetID,
      selectedGoogleSheetHeaders,
    };

    setLoading(true);

    try {
      const resData = await phoneNumbersCreate(
        phoneNumberFormData,
        userData.orgId
      );

      if (resData?.id) {
        reqBody.phoneNumberId = resData.id;
        setLoadingText("Phone Number Creation Saved.");
      }
    } catch (error) {}

    let actions = [];

    try {
      const resActionDataForCollectData =
        actionDataForCollectData &&
        (await createAction({
          0: actionDataForCollectData,
          orgId: userData?.orgId,
        }));

      if (resActionDataForCollectData?.id) {
        actions.push(resActionDataForCollectData?.id);
        setLoadingText("Data collection function saved.");
      }
    } catch (error) {}

    try {
      const resActionDataForCallRedirection =
        actionDataForCallRedirection &&
        (await createAction({
          0: actionDataForCallRedirection,
          orgId: userData?.orgId,
        }));

      if (resActionDataForCallRedirection?.id) {
        actions.push(resActionDataForCallRedirection?.id);
        setLoadingText("Call transfer details saved.");
      }
    } catch (error) {}

    if (actions.length > 0) {
      reqBody.actions = actions;
    }

    try {
      await createAgent(reqBody);
      message.success(
        getTranslation("agents.EditAgentDrawer.createAgentSuccess")
      );
      setLoadingText(
        getTranslation("agents.EditAgentDrawer.createAgentSuccess")
      );

      setSelectedAgent(null);
      setSelectedActions([]);
      const data = await fetchAgentByOrgId(userData?.orgId);
      setLoadingText(" ");

      fetchAgents && fetchAgents();
      setAgents(data);
      setLoading(false);
      setOpenCreateAction(false);
    } catch (error) {
      setLoading(false);
      message.error(
        getTranslation("agents.EditAgentDrawer.createAgentFailure")
      );
    }
  };

  const areRelevantValuesEqual = (formData, currentData) => {
    // Iterate over each key in formData and compare with currentData
    for (let key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== currentData[key]) {
        return false;
      }
    }
    return true;
  };

  const handleEditAgent = async (values) => {
    setLoading(true);

    const reqBody = {
      id: agentId,
      promptId: selectedAgent?.prompt?.id,
      agentName: values?.agentName,
      promptContent: values?.promptContent,
      //  generatePrompt(values?.promptContent, selectedActions),
      initialMessage: values?.initialMessage,
      language: values?.language,
      voiceElevanLabId: selecteVoiceId,
      voiceVoCodeId: selectedAgent?.voice?.id,
      actions: selectedActions?.map((action) => action?.id),
      fireBaseDocId: docId,
      selectedAgent,
      selectedGoogleSheetID,
      orgId: userData?.orgId,
      selectedGoogleSheetHeaders,
    };

    const currentPhoneNumber = selectedAgentData?.attachPhoneNumber || {};

    try {
      // Check if all relevant fields in phoneNumberFormData match with currentPhoneNumber
      if (!areRelevantValuesEqual(phoneNumberFormData, currentPhoneNumber)) {
        const resData = await phoneNumbersCreate(
          phoneNumberFormData,
          userData.orgId
        );

        if (resData?.id) {
          reqBody.phoneNumberId = resData.id;
        }
      } else {
        // If the objects are the same, keep the existing phone number ID
        reqBody.phoneNumberId = currentPhoneNumber.id;
      }
    } catch (error) {
      console.error("Failed to create phone number:", error);
    }

    let actions = [];

    // const { type, destinations,function, ...restData } = actionDataForCallRedirection;
    // try {
    //   const resActionDataForCollectData =
    //     actionDataForCollectData &&
    //     (await updateAction({
    //       data:actionDataForCollectData,
    //       orgId: userData?.orgId,
    //     },"a945ea2c-86d1-44cc-afe6-c820cd876c20"));

    //   if (resActionDataForCollectData?.id) {
    //     actions.push(resActionDataForCollectData?.id);
    //   }
    // } catch (error) {}

    // try {
    //   const resActionDataForCallRedirection =
    //     actionDataForCallRedirection &&
    //     (await updateAction(
    //       {
    //         data:{
    //           function:actionDataForCallRedirection?.function,
    //           destinations:actionDataForCallRedirection?.destinations,
    //         },
    //         orgId: userData?.orgId,
    //       },
    //       "aafe5dd5-7813-4438-8cbb-901f0e4d75c8"
    //     ));

    //   if (resActionDataForCallRedirection?.id) {
    //     actions.push(resActionDataForCallRedirection?.id);
    //   }
    // } catch (error) {}

    try {
      const resActionDataForCollectData =
        actionDataForCollectData &&
        (await createAction({
          0: actionDataForCollectData,
          orgId: userData?.orgId,
        }));

      if (resActionDataForCollectData?.id) {
        actions.push(resActionDataForCollectData?.id);
        setLoadingText("Data collection details updated.");
      }
    } catch (error) {}

    try {
      const resActionDataForCallRedirection =
        actionDataForCallRedirection &&
        (await createAction({
          0: actionDataForCallRedirection,
          orgId: userData?.orgId,
        }));

      if (resActionDataForCallRedirection?.id) {
        actions.push(resActionDataForCallRedirection?.id);
        setLoadingText("Call tranfer details updated.");
      }
    } catch (error) {}

    if (actions.length > 0) {
      reqBody.actions = actions;
    }

    try {
      await updateAgent(reqBody);
      message.success(
        getTranslation("agents.EditAgentDrawer.updateAgentSuccess")
      );
      setLoadingText(
        getTranslation("agents.EditAgentDrawer.updateAgentSuccess")
      );
      fetchAgents && fetchAgents();
      setLoading(false);
      setLoadingText("");
      setOpenCreateAction(false);
    } catch (error) {
      setLoading(false);
      message.error(
        getTranslation("agents.EditAgentDrawer.updateAgentFailure")
      );
    }
  };

  const handleCancel = () => {
    setSelectedAgent(null);
    form.resetFields();
    setSelectedActions([]);
  };

  const handleActionTypeChange = (value) => {
    setActionType(value);
  };

  const handleAddAction = (value) => {
    const action = actions.find((action) => action.id === value);
    if (action) {
      setSelectedActions([...selectedActions, action]);
    }
  };

  const handleDiscardAction = (id) => {
    setSelectedActions(selectedActions.filter((action) => action.id !== id));
  };

  const fetchUpdatedActions = async (newAction) => {
    setOpenCreateAction(false);
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
    setSelectedActions([...selectedActions, newAction]);
  };

  const [actionDataForCallRedirection, setActionDataForCallRedirection] =
    useState({});
  const [actionDataForCollectData, setActionDataForCollectData] = useState({});

  const handleActionDataChangeFroCallRedirection = (updatedData) => {
    setActionDataForCallRedirection(updatedData);
  };

  const handleActionDataChangeCollectUserData = (updatedData) => {
    setGoogleSheetHeaders(getPropertiesKeys(updatedData));
    setActionDataForCollectData(updatedData);
  };

  return (
    <div className="">
      <GlobalModal
        noFooter
        visible={openCreateAction}
        onCancel={() => setOpenCreateAction(!openCreateAction)}
        requiredPermission={"canCreateActions"}
      >
        <CreateActionPage fetchUpdatedActions={fetchUpdatedActions} />
      </GlobalModal>
      <div className="w-full">
        {loading ? (
          <div className="flex flex-col items-center justify-center w-full h-[500px]">
            <Spin />
            <Typography.Text>{loadingText}</Typography.Text>
          </div>
        ) : (
          <Form
            form={form}
            onFinish={handleAgentCreateOrUpdate}
            layout="vertical"
            requiredMark={false}
          >
            {/* <Typography.Title level={2}>Persona</Typography.Title>
            <Typography.Paragraph>
              Customize your AI Agent’s name, personality, and tone of voice.
            </Typography.Paragraph> */}

            <div className="flex">
              <div>
                <Form.Item
                  name="agentName"
                  label={
                    <Label required>
                      {getTranslation("agents.EditAgentDrawer.agentNameLabel")}
                    </Label>
                  }
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "agents.EditAgentDrawer.agentNameError"
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslation(
                      "agents.EditAgentDrawer.agentNamePlaceholder"
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="initialMessage"
                  label={
                    <Label required>
                      {getTranslation(
                        "agents.EditAgentDrawer.welcomeMessageLabel"
                      )}
                    </Label>
                  }
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "agents.EditAgentDrawer.welcomeMessageError"
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslation(
                      "agents.EditAgentDrawer.welcomeMessagePlaceholder"
                    )}
                  />
                </Form.Item>

                {/* <Form.Item
                  name="voice"
                  label={
                    <Label required>
                      {getTranslation("agents.EditAgentDrawer.voiceLabel")}
                    </Label>
                  }
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "agents.EditAgentDrawer.voiceError"
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder={getTranslation(
                      "agents.EditAgentDrawer.voiceLabel"
                    )}
                    dropdownRender={(menu) => (
                      <div>
                        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                          {voices.map((voice) => (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelecteVoiceId(voice.voice_id);
                                form.setFieldsValue({ voice: voice.name });
                              }}
                              key={voice.voice_id}
                              className={`cursor-pointer hover:text-blue-500 flex justify-between items-center px-4 py-1 ${
                                selecteVoiceId === voice.voice_id
                                  ? "bg-blue-200 rounded-sm "
                                  : ""
                              }`}
                            >
                              <span>{voice.name}</span>
                              <Button
                                icon={
                                  playingVoiceId === voice.voice_id ? (
                                    <PauseCircleOutlined />
                                  ) : (
                                    <PlayCircleOutlined />
                                  )
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePlayPause(voice);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </Form.Item> */}

                <Form.Item
                  name="voice"
                  label={<Label required>Voice</Label>}
                  rules={[{ required: true, message: "Please select a voice" }]}
                >
                  <Select
                    placeholder="Select a voice"
                    dropdownRender={(menu) => (
                      <div>
                        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                          {voices.map((voice) => (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelecteVoiceId(voice.id);
                                form.setFieldsValue({ voice: voice.name });
                              }}
                              key={voice.id}
                              className={`cursor-pointer hover:text-blue-500 flex justify-between items-center px-4 py-1 ${
                                selecteVoiceId === voice.id
                                  ? "bg-blue-200 rounded-sm "
                                  : ""
                              }`}
                            >
                              <span>
                                {voice.name} ({voice?.language})
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="language"
                  label={
                    <Label required>
                      {getTranslation("agents.EditAgentDrawer.languageLabel")}
                    </Label>
                  }
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "agents.EditAgentDrawer.languageError"
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder={getTranslation(
                      "agents.EditAgentDrawer.languageLabel"
                    )}
                  >
                    {languages.map((language) => (
                      <Option key={language.value} value={language.value}>
                        {language.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="promptContent"
                  label={
                    <Label required>
                      {getTranslation("agents.EditAgentDrawer.promptLabel")}
                    </Label>
                  }
                  rules={[
                    {
                      required: true,
                      message: getTranslation(
                        "agents.EditAgentDrawer.promptError"
                      ),
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={10}
                    placeholder={getTranslation(
                      "agents.EditAgentDrawer.promptPlaceholder"
                    )}
                  />
                </Form.Item>
                <Typography.Text
                  type="secondary"
                  className="text-xs mt-[-20px] mb-4 block"
                >
                  {getTranslation("agents.EditAgentDrawer.promptInfo")}
                </Typography.Text>

                <CollapsibleUI
                  selectedAgentData={selectedAgentData}
                  handleImportPhoneNumber={(e) => setPhoneNumberFormData(e)}
                  handleActionDataChangeFroCallRedirection={
                    handleActionDataChangeFroCallRedirection
                  }
                  handleActionDataChangeCollectUserData={
                    handleActionDataChangeCollectUserData
                  }
                  setSelectedGoogleSheetID={setSelectedGoogleSheetID}
                />
              </div>
            </div>

            <Form.Item className="mt-10 w-full">
              <Button
                loading={loading}
                type="primary"
                className="w-full"
                htmlType="submit"
              >
                {selectedAgent ? "Update Changes" : " Save Changes"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default EditAgentDrawer;
