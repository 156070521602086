import React from "react";
import { Modal, Typography, Button } from "antd";
import { useAuth } from "../AuthContext";
import { useLocalization } from "../context/LocalizationContext"; // Localization import
import "./GlobalModal.css";

const GlobalDeleteModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  requiredPermission,
  title,
}) => {
  const { getTranslation } = useLocalization(); // Use localization hook
  const { plan, userData } = useAuth();

  // Function to check if the required permission exists in the permissions array
  const hasPermission = () => {
    return plan?.permissions?.some(
      (permission) => permission.name === requiredPermission
    );
  };

  const isAuthorized = userData.roleId === "owner" || hasPermission();

  
  return (
    <Modal
      title={
        isAuthorized
          ? title ||
            getTranslation("organization.GlobalDeleteModal.title_authorized")
          : getTranslation(
              "organization.GlobalDeleteModal.title_not_authorized"
            )
      }
      visible={visible}
      onOk={isAuthorized ? onOk : onCancel}
      onCancel={onCancel}
      confirmLoading={loading}
      footer={
        isAuthorized
          ? [
              <Button key="back" onClick={onCancel}>
                {getTranslation("organization.GlobalDeleteModal.cancel_button")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={onOk}
              >
                {getTranslation(
                  "organization.GlobalDeleteModal.confirm_button"
                )}
              </Button>,
            ]
          : null
      }
    >
      {isAuthorized ? (
        <Typography.Text>
          {getTranslation("organization.GlobalDeleteModal.delete_confirmation")}
        </Typography.Text>
      ) : (
        <div className="blurred-content">
          <Typography.Text>
            {getTranslation(
              "organization.GlobalDeleteModal.not_authorized_message"
            )}
          </Typography.Text>
        </div>
      )}
    </Modal>
  );
};

export default GlobalDeleteModal;
