// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  // dev
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCYTTtuzkt1zYx5jmn63Fug0mTKOND5yR4",
//   authDomain: "leadsmotion.firebaseapp.com",
//   databaseURL: "https://leadsmotion-default-rtdb.firebaseio.com",
//   projectId: "leadsmotion",
//   storageBucket: "leadsmotion.appspot.com",
//   messagingSenderId: "197697608292",
//   appId: "1:197697608292:web:f9cdb0db177be101135d71",
//   measurementId: "G-RMZ1EL5MSY"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore, app };
