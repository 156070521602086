import React, { useState, useEffect } from "react";
import { Card, Button, Form, Input, DatePicker, Select, message } from "antd";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import axios from "axios";
import apiClient from "../../api/index";
import moment from "moment-timezone";
import { useLocalization } from "../../context/LocalizationContext";

const { Option } = Select;

const Campaigns = () => {
  const { getTranslation } = useLocalization();
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const orgSnapshot = await getDocs(
          collection(firestore, "organizations")
        );
        const orgs = orgSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrganizations(orgs);
      } catch (error) {
        message.error(
          getTranslation("campaigns.Campaigns.fetch_organizations_error")
        );
      }
    };

    fetchOrganizations();
  }, []);

  const fetchUsers = async (orgId) => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const usersList = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((user) => user.orgId === orgId);
      setUsers(usersList);
    } catch (error) {
      message.error(getTranslation("campaigns.Campaigns.fetch_users_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    fetchUsers(value);
  };

  const handleSubmit = async (values) => {
    const { campaignName, campaignTime } = values;
    const formattedTime = moment(campaignTime)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD HH:mm:ss");
    try {
      await apiClient.post("/campaigns", {
        campaignName,
        campaignTime: formattedTime,
        users: selectedUsers,
      });
      message.success(
        getTranslation("campaigns.Campaigns.campaign_created_success")
      );
    } catch (error) {
      message.error(
        getTranslation("campaigns.Campaigns.campaign_creation_error")
      );
    }
  };

  return (
    <div className="p-6">
      <Card className="w-full">
        <h2 className="text-xl mb-4">
          {getTranslation("campaigns.Campaigns.create_campaign")}
        </h2>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="campaignName"
            label={getTranslation("campaigns.Campaigns.campaign_name")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "campaigns.Campaigns.campaign_name_required"
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="campaignTime"
            label={getTranslation("campaigns.Campaigns.campaign_time")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "campaigns.Campaigns.campaign_time_required"
                ),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="organization"
            label={getTranslation("campaigns.Campaigns.select_organization")}
            rules={[
              {
                required: true,
                message: getTranslation(
                  "campaigns.Campaigns.organization_required"
                ),
              },
            ]}
          >
            <Select onChange={handleOrgChange}>
              {organizations.map((org) => (
                <Option key={org.id} value={org.id}>
                  {org.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="users"
            label={getTranslation("campaigns.Campaigns.select_users")}
            rules={[
              {
                required: true,
                message: getTranslation("campaigns.Campaigns.users_required"),
              },
            ]}
          >
            <Select
              mode="multiple"
              value={selectedUsers}
              onChange={setSelectedUsers}
              loading={loading}
            >
              {users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {getTranslation("campaigns.Campaigns.submit_button")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Campaigns;
