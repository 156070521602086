// src/pages/PaymentSuccess.js
import React from "react";
import { Card, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

const { Title, Paragraph } = Typography;

const PaymentSuccess = () => {
  const { getTranslation } = useLocalization(); // Use the localization hook
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Card className="max-w-md w-full text-center">
        <CheckCircleOutlined style={{ fontSize: "48px", color: "green" }} />
        <Title level={2} className="text-green-500 mt-4">
          {getTranslation("payments.PaymentSuccess.title")}
        </Title>
        <Paragraph>
          {getTranslation("payments.PaymentSuccess.description")}
        </Paragraph>
        <Button
          type="primary"
          onClick={handleGoBack}
          style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
        >
          {getTranslation("payments.PaymentSuccess.goBackButton")}
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
