import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Popconfirm,
  Breadcrumb,
  Divider,
} from "antd";
import {
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import GenericTable from "../../components/GenericTable";
import { useLocalization } from "../../context/LocalizationContext";

const RequestedOrganizations = () => {
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getTranslation } = useLocalization();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const orgSnapshot = await getDocs(collection(firestore, "organizations"));
      const orgs = orgSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrganizations(orgs);
      fetchUnverifiedUsers(orgs);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.RequestedOrganizations.error_fetching_organizations"
        )
      );
      setLoading(false);
    }
  };

  const fetchUnverifiedUsers = async (orgs) => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const usersList = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (user) => !user.verified && orgs.some((org) => org.id === user.orgId)
        );
      setUsers(usersList);
    } catch (error) {
      message.error(
        getTranslation(
          "organization.RequestedOrganizations.error_fetching_users"
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (userId) => {
    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", userId);
      await updateDoc(userDocRef, { verified: true });
      message.success(
        getTranslation(
          "organization.RequestedOrganizations.user_verified_success"
        )
      );
      fetchOrganizations();
    } catch (error) {
      message.error(
        getTranslation(
          "organization.RequestedOrganizations.error_verifying_user"
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", userId);
      await deleteDoc(userDocRef);
      message.success(
        getTranslation(
          "organization.RequestedOrganizations.user_deleted_success"
        )
      );
      fetchOrganizations();
    } catch (error) {
      message.error(
        getTranslation(
          "organization.RequestedOrganizations.error_deleting_user"
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: getTranslation("organization.RequestedOrganizations.name_column"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: getTranslation("organization.RequestedOrganizations.email_column"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: getTranslation(
        "organization.RequestedOrganizations.organization_column"
      ),
      dataIndex: "orgId",
      key: "orgId",
      render: (orgId) => {
        const org = organizations.find((org) => org.id === orgId);
        return org ? org.name : "N/A";
      },
    },
    {
      title: getTranslation(
        "organization.RequestedOrganizations.actions_column"
      ),
      key: "actions",
      render: (text, record) => (
        <div>
          <Popconfirm
            title={getTranslation(
              "organization.RequestedOrganizations.verify_user_confirm"
            )}
            onConfirm={() => handleVerify(record.id)}
            okText={getTranslation("organization.RequestedOrganizations.yes")}
            cancelText={getTranslation(
              "organization.RequestedOrganizations.no"
            )}
          >
            <Button type="primary" className="mr-2">
              {getTranslation(
                "organization.RequestedOrganizations.verify_button"
              )}
            </Button>
          </Popconfirm>
          <Popconfirm
            title={getTranslation(
              "organization.RequestedOrganizations.delete_user_confirm"
            )}
            onConfirm={() => handleDelete(record.id)}
            okText={getTranslation("organization.RequestedOrganizations.yes")}
            cancelText={getTranslation(
              "organization.RequestedOrganizations.no"
            )}
          >
            <Button type="danger">
              {getTranslation(
                "organization.RequestedOrganizations.delete_button"
              )}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white w-full h-full">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: getTranslation(
                "organization.RequestedOrganizations.breadcrumb_title"
              ),
            },
          ]}
        />
        <Divider />
      </div>

      <div>
        <GenericTable
          columns={columns}
          data={users}
          rowKey="id"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default RequestedOrganizations;
