// AppRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import CreateSequence from "../pages/sequences/CreateSequence";
import Sequences from "../pages/sequences/Sequences";
import NextStep from "../pages/sequences/NextStep";
import Users from "../pages/users/Users";
import InviteUserPage from "../pages/users/InviteUserPage";
import Settings from "../pages/Settings";
import CallPage from "../pages/calls/CallPage";
import EditAgent from "../pages/agents/EditAgent";
import AgentCatalog from "../pages/agents/AgentCatalogPage";
import ComingSoon from "../pages/others/ComingSoon";
import Actions from "../pages/actions/Actions";
import CreateActionPage from "../pages/actions/EditAction";
import Login from "../pages/auth/Login";
import VerifyAccountPage from "../pages/users/VerifyAccountPage";
import UnauthorizedPage from "../pages/others/UnauthorizedPage";
import PricingPage from "../pages/payments/PricingPage";
import PaymentSuccess from "../pages/payments/PaymentSuccess";
import PaymentCancel from "../pages/payments/PaymentCancel";
import Signup from "../pages/auth/Signup";
import RoleManagement from "../pages/roles/RoleManagement";
import PlanManagement from "../pages/plan/PlanManagement";
import OrganizationManagement from "../pages/organization/OrganizationManagement";
import OrgMemberManagement from "../pages/organization/OrgMemberManagement";
import PermissionManagement from "../pages/users/PermissionManagement";
import TestPage from "../pages/others/TestPage";
import { useAuth } from "../AuthContext";
import withAuthorization from "../hoc/withAuthorization";
import UnVerifiedPage from "../pages/auth/UnVerifiedPage";
import RequestedOrganizations from "../pages/organization/RequestedOrganizations";
import OrganizationLeads from "../pages/organization/OrganizationLeads";
import ListOfOrgs from "../pages/organization/ListOfOrgs";
import BlockPage from "../pages/auth/BlockPage";
import PhoneNumberListPage from "../pages/phoneNumber/PhoneNumberListPage";
import InComingCalls from "../pages/calls/InComingCalls";
import OutGoingCalls from "../pages/calls/OutGoingCalls";
import GoogleSheetsIntegration from "../pages/googleSheets/GoogleSheetsIntegration";
import { Integrations } from "../pages/integrations";

const AppRoutes = () => {
  const { user, userData, organization, plan } = useAuth();

  if (!user) {
    return (
      <Routes>
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  }

  if (userData && !userData?.enabled) {
    return (
      <Routes>
        <Route path="*" element={<BlockPage />} />
      </Routes>
    );
  }

  if (userData && userData?.invitedBy && !userData.isPasswordUpdated) {
    return (
      <Routes>
        <Route path="/verify-account" element={<VerifyAccountPage />} />
        <Route path="*" element={<VerifyAccountPage />} />
      </Routes>
    );
  }

  if (!userData.verified) {
    return (
      <Routes>
        <Route path="*" element={<UnVerifiedPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Dashboard Routes */}
      <Route
        path="/"
        element={withAuthorization(Dashboard, "canViewDashboard")()}
      />

      {/* Sequence Routes */}
      <Route
        path="/sequences/update"
        element={withAuthorization(CreateSequence, "canCreateSequences")()}
      />
      <Route
        path="/sequences"
        element={withAuthorization(Sequences, "canListSequences")()}
      />
      <Route path="/next-step" element={<NextStep />} />

      {/* User Routes */}
      <Route
        path="/users"
        element={withAuthorization(Users, "canListLeads")()}
      />
      <Route
        path="/invite-user"
        element={withAuthorization(InviteUserPage, "canUseInviteUser")()}
      />
      <Route
        path="/users/create"
        element={withAuthorization(InviteUserPage, "canCreateLeads")()}
      />
      <Route
        path="/users/edit/:userId"
        element={withAuthorization(InviteUserPage, "canUseUsersEdit")()}
      />

      {/* Permissions */}
      <Route
        path="/permissions"
        element={withAuthorization(
          PermissionManagement,
          "canListPermissions"
        )()}
      />

      {/* Agent Routes */}
      <Route
        path="/agents"
        element={withAuthorization(AgentCatalog, "canListAgents")()}
      />
      <Route
        path="/agents/update"
        element={withAuthorization(EditAgent, "canCreateAgents")()}
      />
      <Route
        path="/agents/update/:id"
        element={withAuthorization(EditAgent, "canEditAgents")()}
      />

      {/* sheets google */}
      <Route
        path="/google-sheets"
        element={<GoogleSheetsIntegration/>}
      />
      {/* Settings Routes */}
      <Route
        path="/settings/integrations"
        element={withAuthorization(Integrations, "canIntegrateHubspot")()}
      />

      {/* Call Routes */}
      {/* <Route path="/calls" element={<CallPage />()} /> */}
      <Route
        path="/calls"
        element={withAuthorization(CallPage, "canListCalls")()}
      />

      <Route
        path="/calls/inbound"
        element={withAuthorization(InComingCalls, "canListCalls")()}
      />

      <Route
        path="/calls/outbound"
        element={withAuthorization(OutGoingCalls, "canListCalls")()}
      />

      {/* Plan Routes */}
      <Route
        path="/plans"
        element={withAuthorization(PlanManagement, "canListPlans")()}
      />

      {/* Roles Routes */}
      <Route
        path="/roles"
        element={withAuthorization(RoleManagement, "canListRoles")()}
      />

      {/* Organization Management Routes */}
      <Route
        path="/organizations"
        element={withAuthorization(
          OrganizationManagement,
          "canViewOrganizations"
        )()}
      />
      <Route
        path="/organizations/:id/members"
        element={withAuthorization(OrgMemberManagement, "canListMembers")()}
      />

      <Route
        path="/organizations/verifications"
        element={withAuthorization(
          RequestedOrganizations,
          "canListRequestedOrganizations"
        )()}
      />

      <Route
        path="/phone-numbers"
        element={withAuthorization(
          PhoneNumberListPage,
          "canImportPhoneNumber"
        )()}
      />

      <Route
        path="/organizations/contacts"
        element={withAuthorization(OrganizationLeads, "canListLeads")()}
      />

      <Route
        path="/organizations/list"
        element={withAuthorization(ListOfOrgs, "canListOrganizations")()}
      />

      {/* Action Routes */}
      <Route
        path="/actions"
        element={withAuthorization(Actions, "canListActions")()}
      />

      <Route
        path="/actions/update"
        element={withAuthorization(CreateActionPage, "canCreateActions")()}
      />

      <Route
        path="/actions/update/:id"
        element={withAuthorization(CreateActionPage, "canEditActions")()}
      />
      {/* Payment Routes */}
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/payment/success" element={<PaymentSuccess />} />
      <Route path="/payment/cancel" element={<PaymentCancel />} />

      {/* Auth Routes */}
      <Route path="/login" element={<Login />} />

      {/* Settings Routes */}
      <Route path="/settings" element={<Settings />} />

      {/* Other Routes */}
      <Route path="/coming-soon" element={<ComingSoon />} />

      <Route path="/test" element={<TestPage />} />
    </Routes>
  );
};

export default AppRoutes;
