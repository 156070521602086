import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Card,
  Typography,
  Space,
  message,
  Drawer,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createAction, fetchActions } from "../../api/actionApi";
import { useAuth } from "../../AuthContext";
import Label from "../../components/Label";
import { useLocalization } from "../../context/LocalizationContext";

const { Option } = Select;

const CreateActionPage = ({ fetchUpdatedActions, actionType }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const [actions, setActions] = useState([]);
  const [show, setShow] = useState(false);
  const { serviceConfig } = useAuth();
  const service = serviceConfig?.service;
  const { getTranslation } = useLocalization();

  const handleCreateAction = async (values) => {
    if (values.name?.includes(" ")) {
      message.error(
        getTranslation("actions.CreateActionPageNew.name_space_error")
      );
      return;
    }
    try {
      setLoading(true);
      let actionData;
      if (actionType === "action_transfer_call") {
        if (service === "vapi") {
          actionData = [
            {
              type: "transferCall",
              destinations: [
                {
                  type: "phoneNumber",
                  number: "+" + values.phoneNumber,
                  message: values.description,
                },
              ],
              function: {
                name: values?.name,
                description: getTranslation(
                  "actions.CreateActionPageNew.message_spoken_during_call_redirect"
                ),
                parameters: {
                  type: "object",
                  properties: {
                    destination: {
                      type: "string",
                      enum: ["+" + values.phoneNumber],
                      description: values.description,
                    },
                  },
                  required: ["destination"],
                },
              },
            },
          ];
        } else {
          actionData.config.phoneNumber = values.phoneNumber;
        }
      } else if (actionType === "action_external") {
        if (service === "vapi") {
          // For "vapi" service

          actionData = [
            {
              type: "function",
              function: {
                name: values.name,
                async: false,
                parameters: {
                  type: "object",
                  properties: values.inputSchema.reduce((acc, curr) => {
                    acc[curr.name] = { type: curr.type };
                    return acc;
                  }, {}),
                },
              },
              server: {
                url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/during-call-processing`,
              },
            },
          ];
        } else {
          // For other services (like vocode)
          actionData = {
            type: values.type,
            config: {
              speakOnSend: true,
              speakOnReceive: true,
            },
            actionTrigger: {
              type: "action_trigger_function_call",
              config: {},
            },
          };
          actionData.config.description = values.description;
          actionData.config.name = values.name;
          actionData.config.url =
            process.env.REACT_APP_SERVER_URL + "/leads/save-user-details";
          actionData.config.inputSchema = {
            type: "object",
            properties: values.inputSchema.reduce((acc, curr) => {
              acc[curr.name] = { type: curr.type };
              return acc;
            }, {}),
          };
        }
      }

      const res = await createAction({ ...actionData, orgId: userData?.orgId });
      setLoading(false);
      message.success(
        getTranslation("actions.CreateActionPageNew.action_created_success")
      );
      if (fetchUpdatedActions) {
        fetchUpdatedActions(res);
      }
    } catch (error) {
      setLoading(false);
      message.error(
        getTranslation("actions.CreateActionPageNew.action_create_failed")
      );
    }
  };

  const getAllAction = async () => {
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
  };

  useEffect(() => {
    getAllAction();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        {actions && actions?.length > 0 && (
          <Button onClick={() => setShow(!show)} className="" size="large">
            {show
              ? getTranslation(
                  "actions.CreateActionPageNew.see_actions_button_hide"
                )
              : getTranslation(
                  "actions.CreateActionPageNew.see_actions_button_show"
                )}
          </Button>
        )}
      </div>

      <Form form={form} layout="vertical" onFinish={handleCreateAction}>
        {actionType === "action_transfer_call" && (
          <Form.Item
            name="phoneNumber"
            label={
              <Label>
                {getTranslation(
                  "actions.CreateActionPageNew.phone_number_required"
                )}
              </Label>
            }
            rules={[
              {
                required: true,
                message: getTranslation(
                  "actions.CreateActionPageNew.phone_number_required"
                ),
              },
            ]}
          >
            <Input
              placeholder={getTranslation(
                "actions.CreateActionPageNew.phone_number_placeholder"
              )}
            />
          </Form.Item>
        )}

        <Form.Item
          name="name"
          label={
            <Label>
              {getTranslation("actions.CreateActionPageNew.enter_action_name")}
            </Label>
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.CreateActionPageNew.enter_action_name"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={
            service === "vapi" && actionType === "action_transfer_call" ? (
              <Label>
                {getTranslation(
                  "actions.CreateActionPageNew.message_spoken_during_call_redirect"
                )}
              </Label>
            ) : (
              <Label>
                {getTranslation(
                  "actions.CreateActionPageNew.action_description"
                )}
              </Label>
            )
          }
          rules={[
            {
              required: true,
              message: getTranslation(
                "actions.CreateActionPageNew.enter_description"
              ),
            },
          ]}
        >
          <Input
            placeholder={
              service === "vapi" && actionType === "action_transfer_call"
                ? getTranslation(
                    "actions.CreateActionPageNew.call_redirect_example"
                  )
                : ""
            }
          />
        </Form.Item>

        {actionType === "action_external" && (
          <>
            <Form.List name="inputSchema">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: getTranslation(
                              "actions.CreateActionPageNew.enter_field_name"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder="Key Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[
                          {
                            required: true,
                            message: getTranslation(
                              "actions.CreateActionPageNew.select_field_type"
                            ),
                          },
                        ]}
                      >
                        <Select placeholder="Field Type">
                          <Option value="string">String</Option>
                          <Option value="number">Number</Option>
                          <Option value="boolean">Boolean</Option>
                          <Option value="object">Object</Option>
                          <Option value="array">Array</Option>
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {getTranslation(
                        "actions.CreateActionPageNew.add_field_button"
                      )}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}

        <Form.Item>
          <Button
            size="small"
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {getTranslation("actions.CreateActionPageNew.create_action_button")}
          </Button>
        </Form.Item>
      </Form>

      {actions && actions?.length > 0 && (
        <Drawer
          title={getTranslation("actions.CreateActionPageNew.drawer_title")}
          visible={show}
          onClose={() => setShow(false)}
        >
          {actions?.map((action) => (
            <Card key={action?.id} className="mb-2">
              <Typography.Text strong>
                {action?.type === "action_transfer_call"
                  ? `${action?.config?.phoneNumber}`
                  : action?.config?.name}
              </Typography.Text>
              {action?.type === "action_external" && (
                <div>
                  <Typography.Title level={5}>
                    {getTranslation("actions.CreateActionPageNew.input_schema")}
                  </Typography.Title>
                  {action?.config.inputSchema?.properties &&
                    Object.entries(action?.config.inputSchema.properties).map(
                      ([name, schema]) => (
                        <div key={name}>
                          <Typography.Text>
                            {name} ({schema.type})
                          </Typography.Text>
                        </div>
                      )
                    )}
                </div>
              )}

              {action?.service === "vapi" && (
                <div>
                  <Typography.Title level={5}>
                    {action?.function?.name}
                  </Typography.Title>

                  {action?.function.parameters?.properties &&
                    Object.entries(action?.function.parameters?.properties).map(
                      ([name, schema]) => (
                        <div key={name}>
                          <Typography.Text>
                            {name} ({schema.type})
                          </Typography.Text>
                        </div>
                      )
                    )}
                </div>
              )}

              <Button
                type="primary"
                className="w-full mt-2"
                onClick={() => {
                  fetchUpdatedActions && fetchUpdatedActions(action);
                }}
              >
                {getTranslation("actions.CreateActionPageNew.add_button")}
              </Button>
            </Card>
          ))}
        </Drawer>
      )}
    </div>
  );
};

export default CreateActionPage;
