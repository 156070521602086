import { Typography, Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import React from "react";
import { useLocalization } from "../context/LocalizationContext";

export default function FooterHeader() {
  const LANGUAGE_OPTIONS = [
    { label: "ENG", value: "en" },
    { label: "FR", value: "fr" },
  ];

  const { changeLanguage } = useLocalization();

  return (
    <div className="px-20 py-2 flex items-center justify-between w-full">
      <div>
        <Typography.Text className="text-sm text-[#525866]">
          © {new Date().getFullYear()} Agentel AI
        </Typography.Text>
      </div>
      <div className="flex items-center gap-1">
        <GlobalOutlined style={{ fontSize: "16px", color: "#525866" }} />
        <Select
          defaultValue={localStorage.getItem("appLanguage") || "en"}
          className="language-select"
          onChange={(value) => changeLanguage(value)}
          options={LANGUAGE_OPTIONS}
          size="small"
        />
      </div>
    </div>
  );
}
