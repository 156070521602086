import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../../context/LocalizationContext";
const Sequences = () => {
  const navigate = useNavigate();
  const { getTranslation } = useLocalization();

  const handleNewSequenceClick = () => {
    navigate("/sequences/update");
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-semibold">
              {getTranslation("sequence.Sequences.title")}
            </h1>
            <p className="text-gray-600">
              {getTranslation("sequence.Sequences.description")}{" "}
              <a href="#" className="text-purple-600">
                {getTranslation("sequence.Sequences.learn_more")}
              </a>
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <Button
              type="primary"
              onClick={handleNewSequenceClick}
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
            >
              {getTranslation("sequence.Sequences.new_sequence")}
            </Button>
            <span className="text-lg font-medium">
              {getTranslation("sequence.Sequences.active_processes")}
            </span>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="border rounded-lg p-16 text-center w-full ">
            <div className="relative h-24 mb-8">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2">
                <div className="h-5 w-5 rounded-full bg-yellow-400"></div>
              </div>
              <div className="absolute bottom-4 left-[45%] transform -translate-x-1/2 translate-y-2">
                <div className="h-5 w-5 rounded-full bg-blue-400"></div>
              </div>
              <div className="absolute bottom-0 right-[42%] transform translate-x-1/2 translate-y-2">
                <div className="h-5 w-5 rounded-full bg-purple-400"></div>
              </div>
            </div>
            <p className="text-gray-700 font-bold mb-4">
              {getTranslation("sequence.Sequences.no_sequences")}
            </p>
            <p className="text-gray-600 mb-8">
              {getTranslation("sequence.Sequences.no_sequences_description")}
            </p>
            <Button
              type="primary"
              onClick={handleNewSequenceClick}
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
            >
              {getTranslation("sequence.Sequences.new_sequence")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sequences;
