import React from "react";
import CallPage from "./CallPage";
import { useLocalization } from "../../context/LocalizationContext"; // Import localization hook

export default function InComingCalls() {
  const { getTranslation } = useLocalization();

  return (
    <div>
      <CallPage
        title={getTranslation("call.IncomingCalls")}
        type="inboundPhoneCall"
      />{" "}
    </div>
  );
}
